import { useState, useEffect } from "react";
import PageTitle from "../../../components/PageTitle/PageTitle";
import api from "../../../utils/apiCall";
import PopupMessage from "../../../components/PopupMessage/PopupMessage";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../components/Pagination/Pagination";
import sortObjectByKey from "../../../utils/sortObjectByKey";

function UserOverview(props) {
    const { updateSelectedUserId } = props;
    const navigate = useNavigate();
    const [popupState, setPopupState] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [users, setUsers] = useState();
    const [id, setId] = useState();
    const [totalResults, setTotalResults] = useState(0);
    const [pageIndex, setPageIndex] = useState(1);

    useEffect(() => {
        getUsers();
    }, [pageIndex]);

    function getUsers(){
        let users;
        let roles;
        api("/users/page/" + pageIndex).then((result) => {
            if (result === undefined || result.success === false){
                setErrorMessage(result.userMessage);
            } else {
                setErrorMessage("");
                users = result.users;
                setTotalResults(result.total);

                api("/roles").then((result) => {
                    if (result === undefined || result.success === false){
                        setErrorMessage(result.userMessage);
                    } else {
                        setErrorMessage("");
                        roles = result.roles;

                        users.forEach(user => {
                            for (let i = 0; i < roles.length; i++){
                                if (user.role === roles[i]._id){
                                    user.role = roles[i].name;
                                    break;
                                }
                            }
                        });

                        sortObjectByKey(users, "lastName");
                        setUsers(users);
                    }
                });
            }
        });
    }

    function deleteUser(){
        api("/users/" + id, "DELETE").then((result) => {
            if (result.success === false){
                setErrorMessage(result.userMessage);
                setPopupState({popupActive: false})
            } else{
                getUsers();
            }
        });
    }

    function updatePopupState() {
        setPopupState((prev) => {
            return {
                ...prev,
                popupActive: !prev.popupActive
            }
        })
    }

    function deletePopup() {
        setPopupState({
            popupActive: true,
            buttonNavigates: false,
            popupType: "confirm",
            popupMessage: "Weet u zeker dat u de geselecteerde gebruiker wilt verwijderen?"
        });
    }

    function handleEditClick(id) {
        updateSelectedUserId(id);
        navigate("/gebruikers/gebruiker-wijzigen");
    }

    const goToPage = (_page) => setPageIndex(_page);

    return (

        <div id="user-overview">
            <div id="user-overview--header">
                <PageTitle title="Gebruikers" />
                <button className="artwork-overview--add-button" onClick={() => {navigate("/gebruikers/gebruiker-toevoegen")}}>
                    Gebruiker toevoegen +
                </button>
            </div>

            <div id="user-overview--body">
                {
                    errorMessage !== "" ? 
                        <p className="error-message">{errorMessage}</p>
                    : users === undefined ? 
                        <p className="loading-message">Laden...</p>
                    : users.length === 0 ?
                        <p className="error-message">Geen kunstwerken gevonden</p>
                    : 
                    <table>
                        <tbody>
                            <tr>
                                <th>Voornaam</th>
                                <th>Infix</th>
                                <th>Achternaam</th>
                                <th>Email</th>
                                <th>Gebruikersnaam</th>
                                <th>Rol</th>
                                <th></th>
                                <th></th>
                            </tr>
                            {
                                users.map((user) => { return (
                                    <tr key={user._id}>
                                        <td>{user.firstName}</td>
                                        <td>{user.insertion}</td>
                                        <td>{user.lastName}</td>
                                        <td>{user.email}</td>
                                        <td>{user.username}</td>
                                        <td>{user.role}</td>
                                        <td><button onClick={() => {handleEditClick(user._id)}}>Wijzig</button></td>
                                        <td><button onClick={() => {setId(user._id); deletePopup();}}>Verwijder</button></td>
                                    </tr>
                                )})
                            }
                        </tbody>
                    </table> 
                }
            </div>    
            
            <Pagination currentPage={pageIndex} total={totalResults} setPage={goToPage}/>

            <PopupMessage
                popupState={popupState}
                updatePopupState={updatePopupState}
                functionToRun={deleteUser}
            />
        </div>
    )
}

export default UserOverview;