function UserInputContainer(props) {
    const { userInputData, updateUserInputData, isAddingUser, errorMessage, leavePagePopup, postUser, updateUser, userroles } = props;

    function handleChange(evt) {
        let value = userInputData[evt.target.name];
        let input = evt.target.value;
        const textOnlyFields = ["firstName", "insertion", "lastName"]
        if (textOnlyFields.includes(evt.target.name)){
            const rgx = /^[A-Za-z]+$/;
            if (rgx.test(input) || input === ""){
                value = input;
            }
        } else {
            value = input;
        }
        updateUserInputData(evt, value);
    }

    return (
        <div id="user-input-container">
            <div className="userinputcontainer--column">
                <div className="userinputcontainer--input-group">
                    <input
                        className="inputfield"
                        type="text"
                        name="firstName"
                        placeholder='Voornaam*'
                        value={userInputData.firstName}
                        onChange={handleChange}
                    />
                    <input
                        className="inputfield"
                        type="text"
                        name="insertion"
                        placeholder='Tussenvoegsel'
                        value={userInputData.insertion}
                        onChange={handleChange}
                    />
                </div>
                <input
                    className="inputfield"
                    type="text"
                    name="lastName"
                    placeholder='Achternaam*'
                    value={userInputData.lastName}
                    onChange={handleChange}
                />
                <input
                    className="inputfield"
                    type="text"
                    name="username"
                    placeholder='Gebruikersnaam*'
                    value={userInputData.username}
                    onChange={handleChange}
                />
                <input
                    className="inputfield"
                    type="text"
                    name="email"
                    placeholder='Email*'
                    value={userInputData.email}
                    onChange={handleChange}
                />
                {
                    isAddingUser && 
                    <div className="userinputcontainer--input-group">
                        <input
                            className="inputfield"
                            type="password"
                            name="password"
                            placeholder='Wachtwoord*'
                            value={userInputData.password}
                            onChange={handleChange}
                        />
                        <input
                            className="inputfield"
                            type="password"
                            name="confirmPassword"
                            placeholder='Wachtwoord bevestigen*'
                            value={userInputData.confirmPassword}
                            onChange={handleChange}
                        />
                    </div>
                }
                
                <div id="userinputcontainer--userrole-selector">
                    <h4>Gebruikersrol</h4>
                    <select name="roleId" onChange={handleChange} value={userInputData.roleId}>
                        {
                            userroles === undefined ?
                                <option value="">Laden...</option> 
                            : userroles.length < 1 ? 
                                <option value="">Niet gevonden</option> 
                            :
                                userroles.map((role) => {
                                    return <option key={role._id} value={role._id}>{role.name}</option>
                                })
                        }
                    </select>
                </div>
            </div>

            <div className="userinputcontainer--column">
                <span></span>
                <div className='buttons-error-container'>
                    <p className='error-message'>&#8203;{errorMessage}</p>
                    {
                        isAddingUser ? 
                            <div id="userinputcontainer--buttons">
                                <button onClick={leavePagePopup}>Annuleren</button>
                                <button onClick={postUser}>Gebruiker toevoegen</button>
                            </div>
                        : 
                            <div id="userinputcontainer--buttons">
                                <button onClick={leavePagePopup}>Annuleren</button>
                                <button onClick={updateUser}>Wijzigingen opslaan</button>
                            </div>
                    }   
                </div>
            </div>
            
            
        </div>
    )
}

export default UserInputContainer;