import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import PopupMessage from "../../../components/PopupMessage/PopupMessage";
import api from "../../../utils/apiCall";
import ArtInputContainer from "../components/ArtInputContainer/ArtInputContainer";

function EditArt(props) {
    const navigate = useNavigate();
    const [popupState, setPopupState] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [artworkInputData, setArtworkInputData] = useState({
        _id: "",
        title: "",
        artist: "",
        medium: "",
        isForSale: false,
        yearOfCreation: "",
        websiteUrl: "",
        formatHeight: "",
        formatWidth: "",
        priceCurrency: "",
        price: "",
        description: "",
        otherInformation: "",
        artworkUrl: ""
    });
    const [recievedArtworkData, setRecievedArtworkData] = useState({hidden: false});
    const [requestSuccess, setRequestSuccess] = useState();
    const [isEditingArt, setIsEditingArt] = useState(false);
    const [artworkTours, setArtworkTours] = useState();

    useEffect(() => {
        getArtwork();
    }, []);

    function getArtwork(){
        api("/art/" + props.selectedArtId).then((result) => {
            if (result === undefined || result.success === false){
                setErrorMessage(result.userMessage);
                
            } else {
                setErrorMessage("");

                if (result.found === false){
                    setRequestSuccess(false);
                    return;
                }
                setRecievedArtworkData(result.art);
                setRequestSuccess(true);
            
                for (const key in artworkInputData) {
                    if(result.art[key]){
                        artworkInputData[key] = result.art[key];
                    }
                }
                
                if (result.art.artworkSize){
                    if (result.art.artworkSize.includes(" x ")){
                        let sizeValues = result.art.artworkSize.split(" x ");
                        artworkInputData.formatHeight = sizeValues[0];
                        artworkInputData.formatWidth = sizeValues[1];
                    } else if (result.art.artworkSize.includes("%$H")) {
                        artworkInputData.formatHeight = result.art.artworkSize.replace("%$H", "");;
                    } else if (result.art.artworkSize.includes("%$W")) {
                        artworkInputData.formatWidth = result.art.artworkSize.replace("%$W", "");;
                    }
                }
                artworkInputData["artworkUrl"] = result.art.artworkUrl;

                api("/tours/art/" + result.art._id).then((result) => {
                    setArtworkTours(result)
                });
            }
        });
    }

    
    function hideArtwork() {
        let body = {hidden: !recievedArtworkData.hidden};
        api("/art/" + artworkInputData._id, "PATCH", body).then((result) => {
            if (result.success === false){
                setErrorMessage(result.userMessage);
            } else{
                setRecievedArtworkData((prev) => {
                    return {
                        ...prev,
                        hidden: result.art.hidden
                    }
                });
            }
        });
    }

    function updateArtwork(){
        let body = {};
        let artworkSize = "";

        for (const key in artworkInputData) {
            if(artworkInputData[key] !== recievedArtworkData[key]){
                if (key !== "formatHeight" && key !== "formatWidth"){
                    body[key] = artworkInputData[key];
                }
            }
        }

        if (artworkInputData.formatHeight && artworkInputData.formatWidth) {
            artworkSize = artworkInputData.formatHeight + " x " + artworkInputData.formatWidth;
        } else if (artworkInputData.formatHeight || artworkInputData.formatWidth) {
            artworkSize = artworkInputData.formatHeight ? artworkInputData.formatHeight + "%$H" : artworkInputData.formatWidth + "%$W";
        }

        if (artworkSize !== recievedArtworkData["artworkSize"]) {
            body["artworkSize"] = artworkSize;

        }

        api("/art/" + artworkInputData._id, "PATCH", body).then((result) => {
            if (result.success === false){
                setErrorMessage(result.userMessage);
            } else{
                setPopupState({
                    popupActive: true,
                    buttonNavigates: true,
                    popupNavigation: "/kunstwerken/",
                    popupType: "accept",
                    popupMessage: "Het kunstwerk is succesvol gewijzigd. U wordt nu teruggestuurd naar het kunstwerken overzicht."
                });
            }
        });
    }
    
    function deleteArtwork(){
        api("/art/" + artworkInputData._id, "DELETE").then((result) => {
            if (result.success === false){
                setErrorMessage(result.userMessage);
                setPopupState({popupActive: false})
            } else{
                setPopupState({
                    popupActive: true,
                    buttonNavigates: true,
                    popupNavigation: "/kunstwerken/",
                    popupType: "accept",
                    popupMessage: "Het kunstwerk is succesvol verwijderd. U wordt nu teruggestuurd naar het kunstwerken overzicht."
                });
            }
        });
    }

    function enableEditMode(){
        window.scrollTo({top: 0, behavior: 'smooth'});
        setIsEditingArt(true);
    }

    function disableEditMode(){
        window.scrollTo({top: 0, behavior: 'smooth'});
        setIsEditingArt(false);
        getArtwork();
    }

    function updateArtworkInputData(evt, value) {
        setArtworkInputData((prev) => { 
            return {
                ...prev,
                [evt.target.name]: value
            }
        });
    }


    function leavePagePopup() {
        setPopupState({
            popupActive: true,
            buttonNavigates: true,
            popupNavigation: "/kunstwerken/",
            popupType: "confirm",
            popupMessage: "Weet u zeker dat u deze pagina wilt verlaten? Niet opgeslagen informatie zal verloren gaan.",
        });
    }

    function deleteArtPopup() {
        setPopupState({
            popupActive: true,
            buttonNavigates: false,
            popupType: "confirm",
            popupMessage: "Weet u zeker dat u dit kunstwerk wilt verwijderen?"
        });
    }

    function updatePopupState() {
        setPopupState((prev) => { 
            return{
                ...prev,
                popupActive: !prev.popupActive
            }
        })  
    }

    function leavePage() {
        navigate("/kunstwerken")
    }




    return (
        <div id="edit-art">
            <div id="edit-art--header">
                <p className="back-link" onClick={isEditingArt ?  leavePagePopup : leavePage}>
                    {"< Terug"}
                </p>
                <PageTitle title={`${isEditingArt ? "Kunstwerk wijzigen": "Kunstwerk weergeven"}`}/>
                <button className={`${recievedArtworkData.hidden ? "edit-art--hidden-artwork-button" : "edit-art--hide-artwork-button"} `} onClick={hideArtwork}>{recievedArtworkData.hidden ? "Kunstwerk tonen" : "Kunstwerk verbergen"}</button>
            </div>

            {
                requestSuccess === undefined ?
                    <p className="loading-message">Laden...</p>
                : requestSuccess === false ?
                    <p className="error-message">Kunstwerk niet gevonden</p>
                :
                    <div id="edit-art--body">
                        <p className={`error-message ${!recievedArtworkData.hidden && "hidden"}`}>Dit kunstwerk is verborgen</p>
                        <h4>&#8203;{isEditingArt && "Velden met een * zijn verplicht"}</h4>
                        <ArtInputContainer 
                            isAddingArt={false}
                            isEditingArt={isEditingArt}
                            inputsAreDisabled={true}
                            artworkInputData={artworkInputData}
                            updateArtworkInputData={updateArtworkInputData}
                            errorMessage={errorMessage}
                            leavePagePopup={leavePagePopup}
                            deleteArtPopup={deleteArtPopup}
                            enableEditMode={enableEditMode}
                            disableEditMode={disableEditMode}
                            updateArtwork={updateArtwork}
                            artworkTours={artworkTours}
                            updateSelectedTourId={props.updateSelectedTourId}
                        />
                    </div>
            }

            <PopupMessage 
                popupState={popupState}
                updatePopupState={updatePopupState}
                functionToRun={deleteArtwork}
            />
        </div>
    )
}

export default EditArt;