//Get initials of username
function getInitials(username){
    if(/\s/.test(username)){
        let words = username.split(" ");
        return words[0].charAt(0) + words[words.length - 1].charAt(0);
    } else {
        return username.charAt(0);
    }
}

export default getInitials;