import { useEffect } from "react";
import { useState } from "react";
import PageTitle from "../../../components/PageTitle/PageTitle";
import PopupMessage from "../../../components/PopupMessage/PopupMessage";
import api from "../../../utils/apiCall";
import TourInputContainer from "../components/TourInputContainer/tourinputcontainer";
import { useNavigate } from "react-router-dom";

const EditArtTour = ({ selectedTourId, updateSelectedArtId }) => {
    const navigate = useNavigate();

    const [popupState, setPopupState] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [tourInputData, setTourInputData] = useState({
        title: "",
        theme: "",
        totalDistance: "",
        type: "",
        description: "",
        art: [],
    });
    const [loaded, setLoaded] = useState(false);

    function updateTourInputData(evt, value) {
        setTourInputData((prev) => {
            return {
                ...prev,
                [evt.target.name]: value,
            };
        });
    }

    function postTour() {
        setErrorMessage("");
        const requiredFields = [
            "title",
            "theme",
            "totalDistance",
            "type",
            "description",
            "art",
        ];
        for (let i = 0; i < requiredFields.length; i++) {
            if (
                tourInputData[requiredFields[i]] === "" ||
                tourInputData[requiredFields[i]] === " " ||
                tourInputData[requiredFields[i]] === undefined
            ) {
                setErrorMessage("Niet alle benodigde velden zijn ingevuld");
                return;
            }
        }
        postTourData();
    }

    function leavePagePopup() {
        setPopupState({
        popupActive: true,
        buttonNavigates: true,
        popupNavigation: "/kunsttours/",
        popupType: "confirm",
        popupMessage:
            "Weet u zeker dat u deze pagina wilt verlaten? Niet opgeslagen informatie zal verloren gaan.",
        });
    }

    const leavePageToViewArt = () => {
        setPopupState({
            popupActive: true,
            buttonNavigates: true,
            popupNavigation: "/kunstwerken/kunstwerk-wijzigen",
            popupType: "confirm",
            popupMessage:
            "Weet u zeker dat u deze pagina wilt verlaten? Niet opgeslagen informatie zal verloren gaan.",
        });
    }

    function updatePopupState() {
        setPopupState((prev) => {
            return {
                ...prev,
                popupActive: !prev.popupActive,
            };
        });
    }

    function postTourData() {
        const firstArtwork = tourInputData.art[0];

        const latitude = firstArtwork ? firstArtwork.latitude || '0' : '0';
        const longitude = firstArtwork ? firstArtwork.longitude || '0' : '0';

        setTourInputData((prev) => ({
            ...prev,
            latitude: latitude,
            longitude: longitude,
        }));
        api(`/tour/${selectedTourId}`, "PATCH", tourInputData).then((result) => {
            if (result.success === false) {
                setErrorMessage(result.userMessage);
            } else {
                setErrorMessage("");
                setPopupState({
                    popupActive: true,
                    buttonNavigates: true,
                    popupNavigation: "/kunsttours/",
                    popupType: "accept",
                    popupMessage:
                        "Tour opslaan is gelukt. U wordt nu teruggestuurd naar het overzicht.",
                });
            }
        });
    }

    const loadTour = async () => {
        if (selectedTourId === "") {
            return navigate("/kunsttours");
        }

        const data = await api(`/tour/${selectedTourId}`);
        if (!data.success || !data.found) {
            return navigate("/kunsttours");
        }

        setTourInputData(data.tour);
        setLoaded(true);
    }

    useEffect(() => {
        loadTour();
    }, []);


    if (!loaded) return;

    return (
        <div id="add-arttour">
            <div id="add-arttour--header">
                <p className="back-link" onClick={leavePagePopup}>
                {"< Terug"}
                </p>
                <PageTitle title="Tour wijzigen" />
            </div>

            <div id="add-arttour--body">
                <TourInputContainer
                    isAddingTour={true}
                    inputsDisabled={false}
                    tourInputData={tourInputData}
                    postTour={postTour}
                    postTourData={postTourData}
                    errorMessage={errorMessage}
                    leavePagePopup={leavePagePopup}
                    updateTourInputData={updateTourInputData}
                    setTourInputData={setTourInputData}
                    edit={tourInputData.art.length > 0}
                    updateSelectedArtId={updateSelectedArtId}
                    leavePageToViewArt={leavePageToViewArt}
                />
                <PopupMessage
                    popupState={popupState}
                    updatePopupState={updatePopupState}
                />
            </div>
        </div>
    );
}


export default EditArtTour;