import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import api from "../../../utils/apiCall";
import ArtworkCard from "./ArtworkCard/ArtworkCard";
import PopupMessage from "../../../components/PopupMessage/PopupMessage";
import Pagination from "../../../components/Pagination/Pagination";

function ArtworkOverview(props){
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState("");
    const [pageIndex, setPageIndex] = useState(1);
    const [errorMessage, setErrorMessage] = useState("");
    const [totalResults, setTotalResults] = useState(50);
    const [artworks, setArtworks] = useState();
    const [inSelectingMode, setInSelectingMode] = useState(false);
    const [selectedArtworks, setSelectedArtworks] = useState([]);
    const [popupState, setPopupState] = useState({});
    const [updateType, setUpdateType] = useState("");

    useEffect(() => {
        getArtworks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex])

    function getArtworks() {
        let url = searchValue !== "" ? "/search/art?q=" + searchValue : "/art/page/" + pageIndex;
        api(url).then((result) => {
            if (result === undefined || result.success === false){
                setErrorMessage(result.userMessage);
                
            } else {
                setErrorMessage("");
                setArtworks(result.art);

                if (result.total) {
                    setTotalResults(result.total);
                }
            }
        });
    }

    function changeArtworks(){
        if(selectedArtworks.length > 0) {
            let isHidden = true;
            for (let i = 0; i < selectedArtworks.length; i++){
                if (selectedArtworks[i].hidden === false){
                    isHidden = false;
                }
            }
            
            selectedArtworks.forEach(selectedArtwork => {
                if (updateType === "delete") {
                    api("/art/" + selectedArtwork.id, "DELETE").then((result) => {
                        if (result.success === false){
                            setErrorMessage(result.userMessage);
                        } else{
                            setSelectedArtworks([]);
                            getArtworks();        
                        }
                    });
                } else if (updateType === 'hide'){                    
                    let body = {hidden: !isHidden};
                    api("/art/" + selectedArtwork.id, "PATCH", body).then((result) => {
                        if (result.success === false){
                            setErrorMessage(result.userMessage);
                        } else{
                            setSelectedArtworks((prev) => {
                                let oldArray = [...prev];
                                let newArray = [];
                                for (let i = 0; i < oldArray.length; i++){
                                    newArray.push({id: oldArray[i].id, hidden: !isHidden, title: oldArray[i].title});
                                }
                                return newArray;
                            });        
                            getArtworks();
                        }        
                    });
                }
            });
        }
    }

    function updateSearchValue(event) {
        const { value } = event.target;
        setSearchValue(value);
    }

    function updatePopupState() {
        setPopupState((prev) => { 
            return{
                ...prev,
                popupActive: !prev.popupActive
            }
        });
    }

    function noArtworksSelectedPopup() {
        setPopupState({
            popupActive: true,
            buttonNavigates: false,
            popupType: "accept",
            popupMessage: "Er zijn geen kunstwerken geselecteerd."
        });
    }

    function handleCardSelect(artworkId, hidden, title) {
        if (selectedArtworks.filter(e => e.id === artworkId).length) {
            setSelectedArtworks((prev) => {
                let array = [...prev];
                const index = array.map(e => e.id).indexOf(artworkId);
                if (index > -1) { 
                    array.splice(index, 1);
                }
                return array;
            });
        } else {
            setSelectedArtworks((prev) => {
                return [...prev, {id: artworkId, hidden: hidden, title: title}];
            });
        }
    }

    const goToPage = (_page) => setPageIndex(_page);

    return (
        <div id="artwork-overview">
            <div id="artwork-overview--header">
                <PageTitle title="Kunstwerken"/>
                <div className='artwork-overview--searchbar'>
                    <input 
                        type="search" 
                        placeholder='Zoeken' 
                        value={searchValue} 
                        onChange={(e) => { updateSearchValue(e); }}
                        onKeyDown={(e) => e.key === 'Enter' && getArtworks()}
                        onBlur={getArtworks}
                    />
                </div>
                <div id="artwork-overview--buttons">
                    <button className="artwork-overview--add-button" onClick={() => {navigate("/kunstwerken/kunstwerk-toevoegen")}}>
                        Kunstwerk toevoegen +
                    </button>

                    {
                        inSelectingMode ? 
                            <div id="artwork-overview--selecting-menu">
                                <button onClick={() => {setSelectedArtworks([]); setInSelectingMode((prev) => {return !prev});}}>
                                    Annuleren
                                </button>{" | "} 
                                <button onClick={() => {setSelectedArtworks([])}}>
                                    Deselecteer alles
                                </button>{" | "} 
                                <button onClick={() => {
                                    setUpdateType('delete');
                                    if (selectedArtworks.length > 0){
                                        setPopupState({
                                            popupActive: true,
                                            buttonNavigates: false,
                                            popupType: "confirm",
                                            popupMessage: "Weet u zeker dat u de geselecteerde kunstwerken wilt werwijderen?"
                                        });
                                    } else {
                                        noArtworksSelectedPopup();
                                    }
                                }}>
                                    Verwijderen
                                </button>{" | "}
                                <button onClick={() => {
                                    setUpdateType('hide');
                                    if (selectedArtworks.length > 0){
                                        setPopupState({
                                            popupActive: true,
                                            buttonNavigates: false,
                                            popupType: "confirm",
                                            popupMessage: "Weet u zeker dat u de geselecteerde kunstwerken wilt verbergen / tonen?"
                                        });
                                    } else {
                                        noArtworksSelectedPopup();
                                    }
                                }}>
                                    Verbergen / tonen
                                </button>{" | "}
                                <button onClick={() => {
                                    if (selectedArtworks.length > 0){
                                        props.updateMultipleSelectedArtId(selectedArtworks);
                                        navigate("/kunstwerken/geselecteerde-kunstwerken-wijzigen")
                                    } else {
                                        noArtworksSelectedPopup();
                                    }
                                }}>
                                    Wijzigen
                                </button>
                            </div>
                        :
                            <button className="artwork-overview--select-button" onClick={() => {setInSelectingMode((prev) => {return !prev})}}>
                                Selecteer kunstwerken
                            </button>
                    }
                    
                </div>
            </div>
            <div id="artwork-overview--body">
                {
                    errorMessage !== "" ? 
                        <p className="error-message">{errorMessage}</p>
                    : artworks === undefined ? 
                        <p className="loading-message">Laden...</p>
                    : artworks.length === 0 ?
                        <p className="error-message">Geen kunstwerken gevonden</p>
                    : 
                        <div id="artworkcard-container">
                            {artworks.map((artwork) => { return (
                                <ArtworkCard 
                                    artwork={artwork}
                                    key={artwork._id}
                                    updateSelectedArtId={props.updateSelectedArtId}
                                    handleCardSelect={handleCardSelect}
                                    inSelectingMode={inSelectingMode}
                                    selectedArtworks={selectedArtworks}
                                />
                            )})}
                        </div>
                }
            </div>
            
            <Pagination currentPage={pageIndex} total={totalResults} setPage={goToPage}/>

            <PopupMessage 
                popupState={popupState}
                updatePopupState={updatePopupState}
                functionToRun={changeArtworks}
            />
        </div>
    )
}

export default ArtworkOverview;