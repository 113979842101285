import { useState } from "react";
import PopupMessage from "../../../components/PopupMessage/PopupMessage";
import PageTitle from "../../../components/PageTitle/PageTitle";
import api from "../../../utils/apiCall";

function EditMultipleArt(props) {
    const [popupState, setPopupState] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [artworkInputData, setArtworkInputData] = useState({
        artist: "",
        medium: "",
        isForSaleYes: false,
        isForSaleNo: false,
        websiteUrl: "",
        formatHeight: "",
        formatWidth: "",
        priceCurrency: "",
        price: "",
        otherInformation: "",
    });

    function updateArtwork(){
        let body = {};

        for (const key in artworkInputData) {
            if(artworkInputData[key] !== ""){
                if (typeof artworkInputData[key] === 'string' && artworkInputData[key].replaceAll(/\s/g,'') === "-"){
                    body[key] = "";
                }else if (key !== "formatHeight" && key !== "formatWidth" && key !== "isForSaleYes" && key !== "isForSaleNo"){
                    body[key] = artworkInputData[key];
                }
            }
        }

        if (artworkInputData.formatHeight !== "" || artworkInputData.formatWidth !== ""){
            if (artworkInputData.formatHeight.replaceAll(/\s/g,'') === "-" || artworkInputData.formatWidth.replaceAll(/\s/g,'') === "-"){
                body["artworkSize"] = "";
            } else if (artworkInputData.formatHeight && artworkInputData.formatWidth) {
                body["artworkSize"] = artworkInputData.formatHeight + " x " + artworkInputData.formatWidth;
            } else {
                body["artworkSize"] = artworkInputData.formatHeight ? artworkInputData.formatHeight + "%$H" : artworkInputData.formatWidth + "%$W";
            }
        }      

        if (artworkInputData.isForSaleYes) {
            body["isForSale"] = true;
        } else if (artworkInputData.isForSaleNo) {
            body["isForSale"] = false;
        }

        props.mutipleSelectedArtId.forEach(selectedArt => {
            api("/art/" + selectedArt.id, "PATCH", body).then((result) => {
                if (result.success === false){
                    setErrorMessage(result.userMessage);
                } else{
                    setPopupState({
                        popupActive: true,
                        buttonNavigates: true,
                        popupNavigation: "/kunstwerken/",
                        popupType: "accept",
                        popupMessage: "De kunstwerken zijn succesvol gewijzigd. U wordt nu teruggestuurd naar het kunstwerken overzicht."
                    });
                }
            });               
        });
    }

    function handleChange(evt) {
        let value = artworkInputData[evt.target.name];
        let input = evt.target.value;

        if (evt.target.name === "price"){
            const rgx = /^[0-9]{1,}\.?[0-9]{0,2}$/;
            input = input.replace(",", ".");
            if (rgx.test(input) || input === ""){
                value = input;
            } else if (input.includes("-")){
                value = "-";
            }
        } else if (evt.target.type === "checkbox") {
            value =  evt.target.checked;
            setArtworkInputData((prev) => { 
                return {
                    ...prev,
                    isForSaleYes: false,
                    isForSaleNo: false
                }
            });
        } else if ((evt.target.name === "formatHeight" || evt.target.name === "formatWidth") && input.replaceAll(/\s/g,'') === "-") {
            setArtworkInputData((prev) => { 
                return {
                    ...prev,
                    formatHeight: "-",
                    formatWidth: "-"
                }
            });
            value = input;
        }else if ((evt.target.name === "formatHeight" || evt.target.name === "formatWidth") && (artworkInputData.formatHeight.replaceAll(/\s/g,'') === "-")) {
            setArtworkInputData((prev) => { 
                return {
                    ...prev,
                    formatHeight: "",
                    formatWidth: ""
                }
            });
            value = input;
        } else {
            value = input;
        }
        setArtworkInputData((prev) => { 
            return {
                ...prev,
                [evt.target.name]: value
            }
        });
    }

    function leavePagePopup() {
        setPopupState({
            popupActive: true,
            buttonNavigates: true,
            popupNavigation: "/kunstwerken/",
            popupType: "confirm",
            popupMessage: "Weet u zeker dat u deze pagina wilt verlaten? Niet opgeslagen informatie zal verloren gaan.",
        });
    }

    function updatePopupState() {
        setPopupState((prev) => { 
            return{
                ...prev,
                popupActive: !prev.popupActive
            }
        })  
    }


    return (
        <div id="edit-art">
        <div id="edit-art--header">
            <p className="back-link" onClick={leavePagePopup}>
                {"< Terug"}
            </p>
            <PageTitle title="Kunstwerken wijzigen"/>
        </div>

        <div id="edit-art--body">
            <div id="multiple-select-important-text">
                <h4>Vul - in om alle opgeslagen waarden te verwijderen.</h4>
                <p>Velden met een * zijn verplicht en kunnen niet verwijderd worden.</p>
            </div>
           
            <div id="art-input-container">
                <div className="add-art--column">
                    <input
                        className="inputfield"
                        type="text"
                        name="artist"
                        placeholder='Artiest*'
                        value={artworkInputData.artist}
                        onChange={handleChange}
                    />
                    <input
                        className="inputfield"
                        type="text"
                        name="medium"
                        placeholder='Medium'
                        value={artworkInputData.medium}
                        onChange={handleChange}
                    />
                    <div className="inputfield checkbox multiple-art--for-sale-container">
                        <span className="greyed-text">Te koop:</span>
                        <input
                            type="checkbox"
                            name="isForSaleYes"
                            checked={artworkInputData.isForSaleYes}
                            onChange={handleChange}
                        />
                        <span>Ja</span>
                        <input
                            type="checkbox"
                            name="isForSaleNo"
                            checked={artworkInputData.isForSaleNo}
                            onChange={handleChange}
                        />
                        <span>Nee</span>
                    </div>
                </div>

                <div className="add-art--column">
                    <input
                        className="inputfield"
                        type="text"
                        name="websiteUrl"
                        placeholder='Link naar website'
                        value={artworkInputData.websiteUrl}
                        onChange={handleChange}
                    />
                    <div className='format-container'>
                        <input
                            className="inputfield"
                            type="text"
                            name="formatHeight"
                            placeholder='Hoogte'
                            value={artworkInputData.formatHeight}
                            onChange={handleChange}
                        />
                        <input
                            className="inputfield"
                            type="text"
                            name="formatWidth"
                            placeholder='Breedte'
                            value={artworkInputData.formatWidth}
                            onChange={handleChange}
                        />
                    </div>
                    <input
                        className="inputfield"
                        type="text"
                        name="priceCurrency"
                        placeholder='Valuta'
                        value={artworkInputData.priceCurrency}
                        onChange={handleChange}
                    />
                    <input
                        className="inputfield"
                        type="text"
                        name="price"
                        placeholder='Prijs'
                        value={artworkInputData.price}
                        onChange={handleChange}
                    />
                    <textarea
                        className="inputfield inputfield--other-information"
                        type="text"
                        name="otherInformation"
                        placeholder='Overige informatie'
                        value={artworkInputData.otherInformation}
                        onChange={handleChange}
                    />
                </div>

                <div className="add-art--column add-art--column-last">
                    <div className="multipleSelected--selection-info">
                        <p>{props.mutipleSelectedArtId.length} kunstwerken geselecteerd:</p>
                        <ul>
                            {props.mutipleSelectedArtId.map((art) => {
                                return (
                                    <li>{art.title}</li>
                                )
                            })}
                        </ul>

                    </div>
                    <div className='buttons-error-container'>
                        <p className='error-message'>&#8203;{errorMessage}</p>

                        <div id="add-art--buttons">
                            <button onClick={leavePagePopup}>Annuleren</button>
                            <button onClick={updateArtwork}>Wijzigingen opslaan</button>
                        </div>
                           
                    </div>
                </div>
            </div>

        </div>

        <PopupMessage 
            popupState={popupState}
            updatePopupState={updatePopupState}
        />
    </div>
    )
}

export default EditMultipleArt;