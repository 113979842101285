const Pagination = (props) => {
    const { currentPage, total, setPage } = props;
    const totalPages = Math.ceil(total / 30);

    if (total <= 30) return;

    const goToPreviousPage = () => {
        if (currentPage > 1) {
            setPage(currentPage - 1);
        }
    }

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setPage(currentPage + 1);
        }
    }

    const goToPage = (_page) => setPage(_page);

    return <>
        <div id="arttour-overview--pagination">
            {currentPage > 1 && <button onClick={() => goToPage(1)}>{"<<"}</button>}
            {currentPage > 1 && <button onClick={goToPreviousPage}>{"<"}</button>}

            {currentPage - 2 > 0 && <button onClick={() => goToPage(currentPage - 2)}>{currentPage - 2}</button>}
            {currentPage - 1 > 0 && <button onClick={() => goToPage(currentPage - 1)}>{currentPage - 1}</button>}
            <button onClick={() => goToPage(currentPage)}>{currentPage}</button>
            {currentPage + 1 <= totalPages && <button onClick={() => goToPage(currentPage + 1)}>{currentPage + 1}</button>}
            {currentPage + 2 <= totalPages && <button onClick={() => goToPage(currentPage + 2)}>{currentPage + 2}</button>}

            {currentPage < totalPages && <button onClick={goToNextPage}>{">"}</button>}
            {currentPage < totalPages && <button onClick={() => goToPage(totalPages)}>{">>"}</button>}
        </div>

        <div id="arttour-overview--pagination-txt">
            Pagina {currentPage} van de {totalPages}
        </div>
    </>
}

export default Pagination;