import {useEffect, useState} from "react";
import { MapContainer } from "react-leaflet/MapContainer";
import { TileLayer } from "react-leaflet/TileLayer";
import { useMap, useMapEvents } from "react-leaflet/hooks";
import { Marker, Popup } from "react-leaflet";
import "leaflet-routing-machine";
import L from "leaflet";

function LeaftletMap(props) {
  const [markerPos, setMarkerPos] = useState([]);
  const center = [52.3722582, 5.2176582];
  var map;

  function HideMap() {
    const MapPopup = document.getElementById("map-popup-inner");
    MapPopup.style.display = "none";
  }
  function ShowMap() {
    const MapPopup = document.getElementById("map-popup-inner");
    MapPopup.style.display = "block";
  }
  function HideAddArtwork() {
    const MapPopup = document.getElementById("map-add-artwork");
    MapPopup.style.display = "none";
  }
  function ShowAddArtWork() {
    const AddArtworkPopup = document.getElementById("map-add-artwork");
    AddArtworkPopup.style.display = "block";
  }

  const [showInitialLocation, setShowInitialLocation] = useState(true);

  const LocationFinder = () => {
    const innerMap = useMap();

    const handleClick = (e) => {
            if (props.changeLocation) {
              setMarkerPos([e.latlng]);

              innerMap.eachLayer((layer) => {
                if (layer instanceof L.Marker || layer instanceof L.Routing.Control) {
                  innerMap.removeLayer(layer);
                }
              });
            } else {
              const newMarkerPos = [...markerPos, e.latlng];
              setMarkerPos(newMarkerPos);
            }

            L.Routing.control({
              waypoints: [L.latLng(e.latlng.lat, e.latlng.lng)],
              draggableWaypoints: false,
            }).addTo(innerMap);
    };

    innerMap.on('click', handleClick);

    if (props.locationToChange && showInitialLocation && props.changeLocation) {
        const { latitude, longitude } = props.locationToChange;
        const marker = L.marker([latitude, longitude]);
        innerMap.addLayer(marker);
        innerMap.setView([latitude, longitude], 16);
        setShowInitialLocation(false);
    }

    return null;
  };





  const RemoveMarker = () => {
    const map = useMapEvents({
      click(e) {
        setMarkerPos(e.latlng);
        const control = markerPos.addTo(map);
        control.spliceWaypoints(0, 2);
      },
    });
  };

  useEffect(() => {
    props.positionClicked(markerPos);
  }, [markerPos]);

  useEffect(() => {
    // props.selectedPositions
  }, []);

  return props.trigger ? (
    <div className="map-popup">
      <div id="map-popup-inner" className="map-popup-inner">
        <div className="map-popup-inner-header">
          <h1>Klik op de kaart om een kunstwerk toe te voegen</h1>
        </div>
        <div className="map-popup-inner-map">
          <MapContainer
            id="MapContainer"
            className="map-popup-content"
            center={center}
            zoom={16}
            maxZoom={19}
          >
            <LocationFinder></LocationFinder>
            <TileLayer
              maxZoom={20}
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </MapContainer>
        </div>
        <div className="map-popup-inner-footer">
          <button
            onClick={() => {
              props.setChangeLocation(false);
              props.setTrigger(false);
              setShowInitialLocation(true);
            }}
          >
            Annuleren
          </button>
          <button
            onClick={() => {
              HideMap();
              props.setChangeLocation(false);
              props.setTrigger(false);
              setShowInitialLocation(true);
            }}
          >
            Verder
          </button>
        </div>
        {props.children}
      </div>

      {/* <div id="map-add-artwork" className="map-add-artwork">
        <div className="map-add-artwork-inner">
          <div className="map-add-artwork-inner-header">
            <h1>Selecteer een kunstwerk</h1>
          </div>
          <div className="map-add-artwork-inner-map">
            <p>Add artworks</p>
          </div>
          <div className="map-add-artwork-inner-footer">
            <button
              className="map-add-artwork-footer-cancel"
              onClick={() => {
                ShowMap();
                HideAddArtwork();
              }}
            >
              Terug
            </button>
            <button
              onClick={() => {
                props.setTrigger(false);
              }}
            >
              Toevoegen
            </button>
          </div>
        </div>
      </div> */}
    </div>
  ) : (
    ""
  );
}

export default LeaftletMap;
