import { NavLink, useLocation } from "react-router-dom";
import getInitials from "../../utils/getInitials";
import setToken from "../../utils/setCookie";

function Navbar(props) {
    const { userIsAmin } = props;

    //Shorten name for small screensizes
    function getShortName(username) {
        if (/\s/.test(username)) {
            let words = username.split(" ");
            return words[0] + " " + words[1].charAt(0) + "...";
        } else {
            return username;
        }
    }

    const location = useLocation();
    if (location.pathname === "/popup") return;

    return (
        <div id="navbar" >
            <div id="navbar--vertical-content">
                <span>
                    <div className="navbar--user-icon">
                        {props.user.imageUrl ? <img src={props.user.imageUrl} alt="profielfoto" /> : <p>{getInitials(props.user.name)}</p>}
                    </div>
                    <p className="navbar--user-name">{props.user.name}</p>
                    <p className="navbar--user-name-short">{getShortName(props.user.name)}</p>
                </span>
                {
                    props.user.name !== "Niet ingelogd" ?
                        <ul>
                            <NavLink
                                to="/kunstwerken"
                                className={({ isActive }) =>
                                    (isActive ? "nav--selected " : "link ") + "navlink"
                                }
                            >
                                <li>
                                    Kunstwerken
                                </li>
                            </NavLink>
                            <NavLink
                                to="/kunsttours"
                                className={({ isActive }) =>
                                    (isActive ? "nav--selected " : "link ") + "navlink"
                                }
                            >
                                <li>
                                    Kunsttours
                                </li>
                            </NavLink>
                            {
                                userIsAmin &&
                                <NavLink
                                    to="/gebruikers"
                                    className={({ isActive }) =>
                                        (isActive ? "nav--selected " : "link ") + "navlink"
                                    }
                                >
                                    <li>
                                        Gebruikers
                                    </li>
                                </NavLink>
                            }
                        </ul> : <span></span>
                }

                <span>
                    <NavLink
                        to="/"
                        onClick={(() => {
                            setToken("token", "", -1);
                            props.updateUser("Niet ingelogd")
                        })}
                    >
                        {props.user.name === "Niet ingelogd" ? "Inloggen" : "Uitloggen"}
                    </NavLink>
                </span>
            </div>

            <div id="navbar--horizontal-content">
                <div className={`navbar--hamburger ${props.hamburgerMenuOpen ? "hamburger-open" : "hamburger-closed"}`} onClick={props.updateHamburgerMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    )
}

export default Navbar;