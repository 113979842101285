import PageTitle from "../../../components/PageTitle/PageTitle";
import { useEffect, useState } from "react";
import api from "../../../utils/apiCall";
import PopupMessage from "../../../components/PopupMessage/PopupMessage";
import UserInputContainer from "../components/UserInputContainer/UserInputContainer";

function EditUser(props) {
    const { selectedUserId } = props;
    const [popupState, setPopupState] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [requestSuccess, setRequestSuccess] = useState();
    const [recievedUserData, setRecievedUserData] = useState();
    const [userInputData, setUserInputData] = useState({
        _id: "",
        firstName: "",
        insertion: "",
        lastName: "",
        username: "",
        email: "",
        roleId: "",
    });
    const [userroles, setUserroles] = useState();

    useEffect(() => {
        getRoles();
        getUser();
    }, []);

    function getRoles() {
        api("/roles").then((result) => {
            if (result === undefined || result.success === false){
                setUserroles([])
            } else {
                setUserroles(result.roles);
            }
        });
    }

    function getUser() {
        if (selectedUserId === "" || selectedUserId === undefined){
            setRequestSuccess(false);
            return;
        }
        api("/users/" + selectedUserId).then((result) => {
            if (result === undefined || result.success === false || result.found === false){
                setRequestSuccess(false);
            } else {
                setRequestSuccess(true);
                setRecievedUserData(result.user);

                setUserInputData((prev) => {
                    let user = {...prev};
                    user._id = result.user._id;
                    user.firstName = result.user.firstName;
                    user.lastName = result.user.lastName;
                    user.username = result.user.username;
                    user.email = result.user.email;
                    user.roleId = result.user.role;

                    if (result.user.insertion !== undefined) {
                        user.insertion = result.user.insertion;
                    }
                    return user;
                });

            }
        });
    }

    function updateUser(){
        let body = {};

        let requiredFields = ["firstName", "lastName", "username", "email"]
        for (let i = 0; i < requiredFields.length; i++){
            if (userInputData[requiredFields[i]].replaceAll(" ", "") === "" || userInputData[requiredFields[i]] === undefined) {
                setErrorMessage("Niet alle verplichte velden zijn ingevuld");
                return;
            }
        }

        for (const key in userInputData) {
            if(userInputData[key] !== recievedUserData[key]){
                if (key !== "insertion" && key !== "roleId"){
                    body[key] = userInputData[key];
                }
            }
        }
        
        if (userInputData.roleId !== recievedUserData.role) {
            body.roleId = userInputData.roleId;
        }

        if ((
                recievedUserData.insertion !== undefined 
                && userInputData.insertion !== recievedUserData.insertion
            ) || (
                recievedUserData.insertion === undefined 
                && userInputData.insertion.replaceAll(" ", "") !== ""
            )) {
            body.insertion = userInputData.insertion;
        }

        api("/users/" + userInputData._id, "PATCH", body).then((result) => {
            if (result.success === false){
                setErrorMessage(result.userMessage);
            } else{
                setPopupState({
                    popupActive: true,
                    buttonNavigates: true,
                    popupNavigation: "/gebruikers/",
                    popupType: "accept",
                    popupMessage: "De gebruiker is succesvol gewijzigd. U wordt nu teruggestuurd naar het gebruikersoverzicht."
                });
            }
        });
    }

    function updateUserInputData(evt, value) {
        setUserInputData((prev) => {
            return {
                ...prev,
                [evt.target.name]: value
            }
        });
    }

    function leavePagePopup() {
        setPopupState({
            popupActive: true,
            buttonNavigates: true,
            popupNavigation: "/gebruikers/",
            popupType: "confirm",
            popupMessage: "Weet u zeker dat u deze pagina wilt verlaten? Niet opgeslagen informatie zal verloren gaan."
        });
    }


    function updatePopupState() {
        setPopupState((prev) => {
            return {
                ...prev,
                popupActive: !prev.popupActive
            }
        })
    }

    return (
        <div id="edit-user">
            <div id="edit-user--header">
                <p className="back-link" onClick={leavePagePopup}>
                    {"< Terug"}
                </p>
                <PageTitle title="Gebruiker wijzigen" />
            </div>

            {
                requestSuccess === undefined ?
                    <p className="loading-message">Laden...</p>
                : requestSuccess === false ?
                    <p className="error-message">Gebruiker niet gevonden</p>
                :
                    <div id="edit-user--body">
                        <h4>Velden met een * zijn verplicht</h4>

                        <UserInputContainer
                            userInputData={userInputData}
                            updateUserInputData={updateUserInputData}
                            isAddingUser={false}
                            errorMessage={errorMessage}
                            leavePagePopup={leavePagePopup}
                            updateUser={updateUser}
                            userroles={userroles}
                        />
                    </div>
            }
            
            <PopupMessage
                popupState={popupState}
                updatePopupState={updatePopupState}
            />
        </div>
    )
}

export default EditUser;