import serverLocation from "./serverLocation";
import getCookie from "./getCookie";
import popup from "./popup";

//Make api call with given parameters
function uploadFile(endpoint, formData) {
    return fetch(serverLocation + "/v1" + endpoint, {
        method: "POST",
        mode: "cors",
        headers: {
            Authorization:  getCookie("token"),
        },
        body: formData,
    })
    .then((res) => res.json()).then(async (data) => {
        const res = await popup(data);
        if (res === true) {
            return await uploadFile(endpoint, formData);
        }
        return res;
    });
}

export default uploadFile;
