import decodeJwt from "./decodeJwt";
import getCookie from "./getCookie";
import setCookie from "./setCookie";

const popup = async (data) => {
    if (!data.success) {
        if (data.message === "UNAUTHORIZED") {
            const token = getCookie("token");
            if (token === "" || token === null) {
                return data;
            }
            const body = decodeJwt(token);

            if (window.location.pathname !== "/" && window.location.pathname !== "/popup" && Date.now() <= body.exp) {
                return await new Promise(resolve => {
                    window.hi = {
                        say: (a) => {
                            setCookie("token", a, 365);
                            resolve(true);
                        }
                    };

                    const popupWindow = window.open("/popup", "popup", "popup=true,width=360,height=490");
                    if (popupWindow === null) return resolve(false);

                    const intervalId = setInterval(() => {
                        if (popupWindow.name !== "popup") {
                            clearInterval(intervalId);
                        }
                    }, 600);
                })
            }
        }
    }
    return data;
}

export default popup;