
const ErrorMessage = (props) => {
    const { message }  = props;

    return <>
        <div id="arttour-overview-error">
            { message }
        </div>
    </>
}

export default ErrorMessage;