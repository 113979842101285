import { useState } from "react";
import PageTitle from "../../../components/PageTitle/PageTitle";
import PopupMessage from "../../../components/PopupMessage/PopupMessage";
import TourInputContainer from "../components/TourInputContainer/tourinputcontainer";
import api from "../../../utils/apiCall";

function AddArtTour(props) {
    const [popupState, setPopupState] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [tourInputData, setTourInputData] = useState({
        title: "",
        theme: "",
        totalDistance: "",
        type: "",
        description: "",
        art: [],
    });

    const { updateSelectedArtId } = props;

    function updateTourInputData(evt, value) {
        setTourInputData((prev) => {
            return {
                ...prev,
                [evt.target.name]: value,
            };
        });
    }

    function postTour() {
        setErrorMessage("");
        const requiredFields = [
            "title",
            "theme",
            "totalDistance",
            "type",
            "description",
            "art",
            "date",
        ];
        for (let i = 0; i < requiredFields.length; i++) {
            if (
                tourInputData[requiredFields[i]] === "" ||
                tourInputData[requiredFields[i]] === " " ||
                tourInputData[requiredFields[i]] === undefined
            ) {
                setErrorMessage("Niet alle benodigde velden zijn ingevuld");
                return;
            }
            tourInputData.date = new Date();
        }

        postTourData();
    }

    function leavePagePopup() {
        setPopupState({
            popupActive: true,
            buttonNavigates: true,
            popupNavigation: "/kunsttours/",
            popupType: "confirm",
            popupMessage:
                "Weet u zeker dat u deze pagina wilt verlaten? Niet opgeslagen informatie zal verloren gaan.",
        });
    }

    const leavePageToViewArt = () => {
        setPopupState({
            popupActive: true,
            buttonNavigates: true,
            popupNavigation: "/kunstwerken/kunstwerk-wijzigen",
            popupType: "confirm",
            popupMessage:
                "Weet u zeker dat u deze pagina wilt verlaten? Niet opgeslagen informatie zal verloren gaan.",
        });
    }

    function updatePopupState() {
        setPopupState((prev) => {
            return {
                ...prev,
                popupActive: !prev.popupActive,
            };
        });
    }

    function postTourData() {
        const firstArtwork = tourInputData.art[0];

        // Set lat en long naar de lat en long van het eerst kunstwerk in de array of gebruik '0' als default
        const latitude = firstArtwork ? firstArtwork.latitude || '0' : '0';
        const longitude = firstArtwork ? firstArtwork.longitude || '0' : '0';


        // update de TourInputData om ook de lat en long te hebben
        const updatedTourInputData = {
            ...tourInputData,
            latitude: latitude,
            longitude: longitude,
        };


        // gebruik de updated tourInput in de API call
        api("/tour", "POST", updatedTourInputData)
            .then((result) => {
                if (result.success === false) {
                    setErrorMessage(result.userMessage);
                } else {
                    setErrorMessage("");
                    setPopupState({
                        popupActive: true,
                        buttonNavigates: true,
                        popupNavigation: "/kunsttours/",
                        popupType: "accept",
                        popupMessage: "Tour toevoegen is gelukt. U wordt nu teruggestuurd naar het overzicht.",
                    });
                }
            })
            .catch((error) => {
                console.error("API Error:", error);
                setErrorMessage("Er is een fout opgetreden bij het toevoegen van de tour.");
            });
    }







    return (
        <div id="add-arttour">
            <div id="add-arttour--header">
                <p className="back-link" onClick={leavePagePopup}>
                    {"< Terug"}
                </p>
                <PageTitle title="Tour toevoegen" />
            </div>

            <div id="add-arttour--body">
                <TourInputContainer
                    isAddingTour={true}
                    inputsDisabled={false}
                    tourInputData={tourInputData}
                    postTour={postTour}
                    postTourData={postTourData}
                    errorMessage={errorMessage}
                    leavePagePopup={leavePagePopup}
                    updateTourInputData={updateTourInputData}
                    setTourInputData={setTourInputData}
                    updateSelectedArtId={updateSelectedArtId}
                    leavePageToViewArt={leavePageToViewArt}
                />
                <PopupMessage
                    popupState={popupState}
                    updatePopupState={updatePopupState}
                />
            </div>
        </div>
    );
}

export default AddArtTour;
