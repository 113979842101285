import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function ArtworkCard(props){
    const navigate = useNavigate();
    const { artwork, handleCardSelect, inSelectingMode, selectedArtworks } = props;
    const [cardIsSelected, setCardIsSelected] = useState(false);

    useEffect(() => {
        if(selectedArtworks.filter(e => e.id === artwork._id).length) {
            setCardIsSelected(true);
        } else {
            setCardIsSelected(false);
        }
    }, [selectedArtworks, artwork._id]);


    function handleCardClick() {
        props.updateSelectedArtId(artwork._id);
        navigate("/kunstwerken/kunstwerk-wijzigen");
    }

    return (
        <div 
            className={`artworkcard ${artwork.hidden && 'artworkcard--hidden-message'} ${cardIsSelected && "artworkcard--selected"}`} 
            data-url={artwork.artworkUrl} 
            onClick={() => inSelectingMode ? handleCardSelect(artwork._id, artwork.hidden, artwork.title) : handleCardClick()}
        >
            {/* <div className="artworkcard--image" Style={`background-image: url(${artwork.artworkUrl})`}></div> */}
            <div className={`artworkcard--image  ${artwork.hidden && 'artworkcard--hidden'}`}>
                <img src={artwork.artworkUrl} className="artworkcard--image" alt="Afbeelding niet gevonden"/>
            </div>
            <div className={`artworkcard--body ${artwork.hidden && 'artworkcard--hidden'}`}>
                <h3>{artwork.title}</h3>
                <h4>{artwork.artist}</h4>
                <p>
                    {
                        artwork.description.length > 300 ?
                            artwork.description.slice(0,300) + "..."
                        :
                            artwork.description
                    }
                </p>
                <div className="artworkcard--buttons">
                    <button onClick={handleCardClick}>Bekijk</button>
                </div>
            </div>
            
        </div>
    )
}

export default ArtworkCard;