import { useNavigate } from "react-router-dom";

//How to use this popup:
//This component has a few required props
// - popupState: this is the state that holds most of our popup settings
//      - popupActive: determines if popup is shown or hidden (boolean)
//      - popupMessage: determines the message on the popup (string)
//      - popupType: determines if popup has 1 or 2 buttons [doorgaan / nee-ja] (string)
//              \-> "accept" == 1 button, "confirm" == 2 buttons
//      - buttonNavigates: determines if a buttons function is to navigate to another page (boolean)
//      - popupNavigation: route to navigate to when buttonNavigates is true (string) (optional)
// - functionToRun: function to run when buttonNavigates is false (function) (optional)
// - updatePopupState: gives popup the ability to disable itself (function)

function PopupMessage(props) {
    const navigate = useNavigate();
    const { popupActive, popupMessage, popupType, popupNavigation, buttonNavigates } = props.popupState;

    function hidePopup() {
        props.updatePopupState();
    }

    return (
        <div className={`popup-message ${!popupActive && "hidden"}`}> 
            <div className="popup-message-box">
                <p>{popupMessage}</p>
                <div className="popup-message-buttons">
                    {
                        popupType === "accept" ? 
                        <button onClick={buttonNavigates ? () => {navigate(popupNavigation)} : props.updatePopupState}>Doorgaan</button>
                        :
                        <div className="popup-message-buttons-container">
                            <button onClick={hidePopup}>Nee</button>
                            <button onClick={buttonNavigates ? () => {navigate(popupNavigation)} : () => {props.functionToRun(); hidePopup();}}>Ja</button>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default PopupMessage;