import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import HamburgerNavbar from "./components/HamburgerNavbar/HamburgerNavbar";
import Login from './pages/Login/Login';
import ForgotPassword from './pages/Login/ForgotPassword/ForgotPassword';
import ArtworkOverview from './pages/Art/ArtworkOverview/ArtworkOverview';
import AddArt from "./pages/Art/AddArt/AddArt";
import EditArt from './pages/Art/EditArt/EditArt';
import EditMultipleArt from './pages/Art/EditMultipleArt/EditMultipleArt';
import ArttourOverview from "./pages/ArtTours/ArtTourOverview/ArtTourOverview"
import AddArtTour from "./pages/ArtTours/AddArtTour/AddArtTour"
import UserOverview from './pages/Users/UserOverview/UserOverview';
import AddUser from './pages/Users/AddUser/AddUser';
import Popup from "./pages/Popup/Popup";
import EditArtTour from "./pages/ArtTours/EditArtTour/EditArtTour";
import api from './utils/apiCall';
import EditUser from './pages/Users/EditUser/EditUser';
import checkAdminAccess from './utils/checkAdminAccess';
import Wrapper from './components/Wrapper/Wrapper';

function App() {
  const [user, setUser] = useState({
    name: "Niet ingelogd",
    hasLoggedIn: false
    //imageUrl: ""
  });
  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);
  const [selectedArtId, setSelectedArtId] = useState();
  const [mutipleSelectedArtId, setMultipleSelectedArtId] = useState([]);
  const [selectedTourId, setSelectedTourId] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [userIsAmin, setUserIsAdmin] = useState(false);

  useEffect(() => {
    api("/auth").then((result) => {
      if (result.success === true){
        let username = result.user.username;
        updateUser(username);
      }
      // else {
      //   //login popup
      // }
    });
    checkAdminAccess(updateUserIsAdmin);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function updateHamburgerMenu(){
    setHamburgerMenuOpen((prev) => {return !prev});
  }

  function updateSelectedArtId(id){
    setSelectedArtId(id);
  }

  function updateSelectedTourId(id){
    setSelectedTourId(id);
  }

  function updateSelectedUserId(id){
    setSelectedUserId(id);
  }

  function updateUser(username){
    if (username !== undefined) {
      setUser({
        ...user,
        name: username
      });
    }
  }

  function updateUserIsAdmin(value) {
    setUserIsAdmin(value)
  }

  function updateMultipleSelectedArtId(selectedArtworks) {
    let artworkList = selectedArtworks;
    let artworkIdList = [];
    for (let i = 0; i < artworkList.length; i++){
      artworkIdList.push({id: artworkList[i].id, title: artworkList[i].title});
    }
    setMultipleSelectedArtId(artworkIdList);
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar 
          user={user} 
          updateUser={updateUser} 
          updateHamburgerMenu={updateHamburgerMenu} 
          hamburgerMenuOpen={hamburgerMenuOpen}
          userIsAmin={userIsAmin}
        />
        <Wrapper>
          <HamburgerNavbar 
            user={user} 
            updateUser={updateUser} 
            hamburgerMenuOpen={hamburgerMenuOpen} 
            updateHamburgerMenu={updateHamburgerMenu}
            userIsAmin={userIsAmin}
          />
          <Routes>
            <Route
              path="/"
              element={
                <Login updateUserIsAdmin={updateUserIsAdmin} updateUser={updateUser}/>
              }
            />
            <Route
              path="/wachtwoordherstellen"
              element={
                <ForgotPassword updateUser={updateUser}/>
              }
            />
            <Route
              path="/kunstwerken"
              element={
                <ArtworkOverview
                  updateSelectedArtId={updateSelectedArtId}
                  updateMultipleSelectedArtId={updateMultipleSelectedArtId}
                />
              }
            />
            <Route
              path="/kunstwerken/kunstwerk-toevoegen"
              element={
                <AddArt/>
              }
            />
            <Route
              path="/kunstwerken/kunstwerk-wijzigen"
              element={
                <EditArt
                  selectedArtId={selectedArtId}
                  updateSelectedTourId={updateSelectedTourId}
                />
              }
            />
            {
              userIsAmin &&
              <>
                <Route
                path="/gebruikers/gebruiker-toevoegen"
                element={
                  <AddUser />
                }
              />
              <Route
                path="/gebruikers/gebruiker-wijzigen"
                element={
                  <EditUser selectedUserId={selectedUserId} />
                }
              />
              <Route
                path="/gebruikers"
                element={
                  <UserOverview updateSelectedUserId={updateSelectedUserId}/>
                }
              />
            </>
            }
            
            <Route
              path="/kunsttours"
              element={
                <ArttourOverview updateSelectedTourId={updateSelectedTourId}/>
              }
            />
            <Route
              path="/kunsttours/kunsttour-toevoegen"
              element={
                <AddArtTour updateSelectedArtId={updateSelectedArtId}/>
              }
            />
            <Route
                path="/kunsttours/kunsttour-wijzigen"
                element={
                    <EditArtTour selectedTourId={selectedTourId} updateSelectedArtId={updateSelectedArtId}/>
                }
            />
            <Route
              path="/kunstwerken/geselecteerde-kunstwerken-wijzigen"
              element={
                <EditMultipleArt
                  mutipleSelectedArtId={mutipleSelectedArtId}
                />
              }
            />
            <Route
              path="/popup"
              element={
                <Popup updateUser={updateUser}/>
              }
            />
          </Routes>
        </Wrapper>
      </BrowserRouter>
    </div>
  );
}

export default App;
