import { useState } from 'react';
import { useNavigate, NavLink } from "react-router-dom";
import api from '../../../utils/apiCall';

function Login(){
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const [loginData, setLoginData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        confirmationToken: "",
        tokenId: ""
    });
    const [currentProcessStep, setCurrentProcessStep] = useState("confirm email");

    function handleChange(evt) {
        const value = evt.target.value;
        setLoginData({
            ...loginData,
            [evt.target.name]: value
        });
    }

    function handleSubmitClick(){
        if (currentProcessStep === "confirm email") {
            let body = {email: loginData.email.toLowerCase()};
            api("/reset", "POST", body).then((result) => {
                    if (result.success === false){
                        setErrorMessage(result.userMessage);
                    } else {
                        setLoginData({
                            ...loginData,
                            tokenId: result.tokenId
                        })
                        setCurrentProcessStep("confirm password");
                        setErrorMessage("");                        
                    }
                }
            );
        } else if (currentProcessStep === "confirm password") {
            if (loginData.password !== loginData.confirmPassword){
                setErrorMessage("Passwords do not match");
                return;
            }

            let spaceRegex = /\s/;
            if (loginData.password.match(spaceRegex)) {
                setErrorMessage("Een wachtwoord mag geen spaties bevatten"); 
                return;
            }

            let pwRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*.,"'/|()`~{}<>?;:=+-_])[a-zA-Z0-9!@#$%^&*.,"'/|()`~{}<>?;:=+-_]{8,}$/;
            if (!loginData.password.match(pwRegex)) {
                setErrorMessage("Een wachtwoord moet bestaan uit ten minste 8 karakters, 1 nummer en 1 speciaal teken"); 
                return;
            }

            let body = {
                password: loginData.password,
                tokenId: loginData.tokenId,
                resetToken: loginData.confirmationToken
            };

            api("/reset/confirm", "POST", body).then((result) => {
                    if (result.success === false){
                        setErrorMessage(result.userMessage);
                    } else {
                        setCurrentProcessStep("done");
                        setErrorMessage("");            
                    }
                }
            );
        } else {
            navigate("/");
        }
    }

    return (
        <div id="login">
            <div id="login--container">
                <h1 id="forgot-password-title">{currentProcessStep === "done" ? "Gelukt!" : "Wachtwoord herstellen"}</h1>
                {
                    currentProcessStep === "confirm email" ? (
                        <label className="input">
                            <p>Email</p>
                            <input
                                type="text"
                                name="email"
                                value={loginData.email}
                                onChange={handleChange}
                                required="required"
                                onKeyDown={(e) => e.key === 'Enter' && handleSubmitClick()}
                            />
                        </label>
                    ) : currentProcessStep === "confirm password" ? (
                        <div>
                            <label className="input">
                                <p>Nieuw wachtwoord</p>
                                <input
                                    type="password"
                                    name="password"
                                    value={loginData.password}
                                    onChange={handleChange}
                                    required="required"
                                    onKeyDown={(e) => e.key === 'Enter' && handleSubmitClick()}
                                />
                            </label>
                            <label className="input">
                                <p>Wachtwoord bevestigen</p>
                                <input
                                    type="password"
                                    name="confirmPassword"
                                    value={loginData.confirmPassword}
                                    onChange={handleChange}
                                    required="required"
                                    onKeyDown={(e) => e.key === 'Enter' && handleSubmitClick()}
                                />
                            </label>
                            <label className="input">
                                <p>Verificatiecode (verstuurd per email)</p>
                                <input
                                    type="text"
                                    name="confirmationToken"
                                    value={loginData.confirmationToken}
                                    onChange={handleChange}
                                    required="required"
                                    onKeyDown={(e) => e.key === 'Enter' && handleSubmitClick()}
                                />
                            </label>
                        </div>
                    ) : <p id="password-reset--final-message">
                            U kunt nu inloggen met het zojuist ingevoerde wachtwoord. Klik op de onderstaande knop om terug naar het inlogscherm te gaan.
                        </p>

                }
               
                <p className='error-message'>&#8203;{errorMessage}</p>

                <button id="loginButton" onClick={handleSubmitClick}>
                    {
                        currentProcessStep === "confirm email" ? "Versturen" 
                        : currentProcessStep === "confirm password" ? "Bevestigen"
                        : currentProcessStep === "done" ? "Inloggen"
                        : undefined
                    }
                </button>

                <NavLink to="/" id={"forgotemail"}>
                    Annuleren
                </NavLink>
            </div>
        </div>
    )
}

export default Login;