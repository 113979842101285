import { useEffect, useState } from "react";
import ErrorMessage from "./ErrorMessage/ErrorMessage";
import decodeJwt from '../../utils/decodeJwt';
import setCookie from '../../utils/setCookie';
import api from "../../utils/apiCall";


const Popup = (props) => {
    const [data, setData] = useState({
        email: "",
        password: ""
    });
    const [error, setError] = useState(null);

    const onChange = (e) => {
        setData((prev) => {return {
            ...prev,
            [e.target.name]: e.target.value
        }});
    }

    const submitLogin = async () => {
        setError(null);

        api("/login", "POST", data).then((result) => {
            if (result.success === false){
                setError(result.userMessage);
            } else {
                window.opener.hi.say(result.token);
                let username = decodeJwt(result.token).username;
                props.updateUser(username);

                setData({
                    email: "",
                    password: ""
                });
                window.close();
            }
        });
    }

    return <>
        <div id="popup--container">
            <div className="header">
                Login
            </div>

            <div className="inputs">
                <label className="input">
                    <p>Email</p>
                    <input 
                        type="text" 
                        name="email" 
                        value={data.email} 
                        onChange={onChange}
                        onKeyDown={(e) => e.key === 'Enter' && submitLogin()}/>
                </label>

                <label className="input">
                    <p>Password</p>
                    <input 
                        type="password" 
                        name="password" 
                        value={data.password} 
                        onChange={onChange}
                        onKeyDown={(e) => e.key === 'Enter' && submitLogin()}/>
                </label>

                <button id="loginButton" onClick={submitLogin}>
                    Login
                </button>
                
                {error !== null && <ErrorMessage message={error}/>}
            </div>
        </div>
    </>
}

export default Popup;