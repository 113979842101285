import { useState, useEffect } from "react";
import SelectArt from "../../AddArtTour/SelectArt/SelectArt";
import LeafletMap from "../LeafletMap/LeafletMap";
import apiCall from "../../../../utils/apiCall";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Buttons from "./Buttons/Buttons";
import LeafletMapEdit from "../LeafletMap/LeafletMapEdit";

function TourInputContainer(props) {
    const {
        tourInputData,
        updateTourInputData,
        errorMessage,
        leavePagePopup,
        postTour,
        setTourInputData,
        edit,
        updateSelectedArtId,
        leavePageToViewArt
    } = props;

    const [leafletMapOpen, setLeafletMapOpen] = useState(false);
    const [selected, setSelected] = useState("");
    const [selectedPositions, setSelectedPositions] = useState([]);
    const [artUpdating, setArtUpdating] = useState(-1);
    const [selectedArt, setSelectedArt] = useState(null);
    const [loadedArt, setLoadedArt] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [buttonsState, setButtonsState] = useState({
        shown: false,
        y: 0,
        x: 0
    });
    const [hovered, setHovered] = useState(-1);
    const [changeLocation, setChangeLocation] = useState(false);

    const handleChangeDropdown = (event) => {
        setSelected(event.target.value);
        setTourInputData((prev) => {
            return {
                ...prev,
                type: event.target.value,
            };
        });
    };

    const openSelectArt = i => {
        setArtUpdating(i);
    }

    const positionClicked = (positions) => {
        if (positions.length === 0) return;

        const existingArt = [...tourInputData.art];
        const newArtObj = [];

        if (changeLocation) {
            existingArt[hovered].longitude = positions[0].lng;
            existingArt[hovered].latitude = positions[0].lat;

            setTourInputData((prev) => ({
                ...prev,
                art: existingArt,
            }));

        } else {
            for (let i = 0; i < positions.length; i++) {
                const position = positions[i];
                let found = false;

                for (let j = 0; j < existingArt.length; j++) {
                    const artData = existingArt[j];

                    if (artData.longitude === position.lng && artData.latitude === position.lat) {
                        found = true;
                        newArtObj.push(artData);
                    }
                }

                if (!found) {
                    newArtObj.push({
                        longitude: position.lng,
                        latitude: position.lat,
                        altitude: 0,
                        viewingDistance: 60,
                        artworkWidth: 200,
                        art: null,
                    });
                }
            }

            const updatedArt = [...existingArt, newArtObj[newArtObj.length - 1]];

            setSelectedPositions(positions);
            setTourInputData((prev) => ({
                ...prev,
                art: updatedArt,
            }));
        }
    };


    function handleChange(evt) {
        let value = tourInputData[evt.target.name];
        let input = evt.target.value;

        if (evt.target.name === "totalDistance"){
            const rgx = /^[0-9]{1,}\.?[0-9]{0,1}$/;
            input = input.replace(",", ".");

            if (rgx.test(input) || input === ""){
                value = input;
            }
        } else {
            value = input;
        }

        updateTourInputData(evt, value);
    }

    const loadArt = async (artId) => {
        const data = await apiCall(`/art/${artId}`);
        if (data.success && data.found) setLoadedArt(prev => {
            prev[artId] = data.art;
            return prev;
        });
    }


    useEffect(() => {
        var newLoadedArt = {};

        var artObj = [];
        for (var i = 0; i < tourInputData.art.length; i++) {
            const art = tourInputData.art[i];
            newLoadedArt[art.art._id] = art.art;
            artObj.push({
                ...art,
                art: art.art._id
            });
        }

        setLoadedArt(newLoadedArt);
        setTourInputData(prev => {
            return {
                ...prev,
                art: artObj
            };
        });
        setLoaded(true);

        setSelected(tourInputData.type);
    }, []);

    useEffect(() => {
        if (selectedArt === null) return;

        var artObj = [...tourInputData.art];
        artObj[artUpdating].art = selectedArt;
        
        if (typeof loadedArt[selectedArt] === "undefined") loadArt(selectedArt);

        setTourInputData((prev) => {
            return {
                ...prev,
                art: artObj,
            };
        });

        setSelectedArt(null);
        setArtUpdating(-1);

    }, [selectedArt]);

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const fromIndex = result.source.index;
        const toIndex = result.destination.index;

        const updatedArt = [...tourInputData.art];

        const [draggedItem] = updatedArt.splice(fromIndex, 1);

        updatedArt.splice(toIndex, 0, draggedItem);

        setTourInputData((prev) => ({
            ...prev,
            art: updatedArt,
        }));
    };




    const updateArt = (i, name, value) => {
        var artObj = [...tourInputData.art];
        artObj[i][name] = value;

        setTourInputData(prev => {
            return {
                ...prev,
                art: artObj
            };
        });
    }

    const itemHover = (e, i) => {
        const rect = e.currentTarget.getBoundingClientRect();

        setButtonsState({
            shown: true,
            x: rect.right,
            y: rect.top + window.scrollY
        });
        setHovered(i);
    }


    const deleteSelectedArt = () => {
        var artObj = tourInputData.art.filter((art, j) => j !== hovered);

        setTourInputData(prev => {
            return {
                ...prev,
                art: artObj
            };
        });
        setButtonsState({
            shown: false,
            x: 0,
            y: 0
        });
    }

    const viewSelectedArt = () => {
        updateSelectedArtId(tourInputData.art[hovered].art);
        leavePageToViewArt();
  }

    const generateDraggableId = (artItem, i) => {
        const baseId = artItem.longitude.toString();
        const uniqueId = artItem.id !== undefined ? artItem.id : i;
        return `${baseId}-${uniqueId}`;
    };

    const handleChangeLocation = () => {
        setLeafletMapOpen(true);
        setChangeLocation(true);
    }

    if (!loaded) return;

  return (
    <div id="arttour-input-container">
      <div className="add-arttour--column">
        <input
          className="inputfield"
          type="text"
          name="title"
          placeholder="Titel"
          value={tourInputData.title}
          onChange={handleChange}
        />
        <input
          className="inputfield"
          type="text"
          name="theme"
          placeholder="Thema"
          value={tourInputData.theme}
          onChange={handleChange}
        />
        <input
          className="inputfield"
          type="text"
          name="totalDistance"
          placeholder="Afstand in km"
          value={tourInputData.totalDistance}
          onChange={handleChange}
        />
        <select
          onChange={handleChangeDropdown}
          value={selected}
          name="dropdownmenu"
          id="dropdownmenu"
        >
          <option disabled={true} value="">
            Selecteer vervoersmiddel
          </option>
                    <option className="select-options" value="Wandelend">
            Wandelend
          </option>
                    <option className="select-options" value="Fietsend">
            Fietsend
          </option>
        </select>

        <h3>Kunstwerken in deze tour:</h3>
                {typeof edit !== "undefined" ? <>
                    {tourInputData.art.length === 0 ? <p>Deze tour bevat nog geen kunstwerken</p> :

                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {tourInputData.art.map((artItem, i) =>
                                            <Draggable key={i} draggableId={generateDraggableId(artItem, i)} index={i}>
                                                {(provided, snapshot) => (
                                                    <div className="position-item" ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps} onMouseEnter={e => itemHover(e, i)}>
                                                        <div className="number">
                                                            {i + 1}
                                                        </div>
                                                        <div className="pos">
                                                            <p>lat: {artItem.latitude.toLocaleString('nl-NL', {maximumFractionDigits: 6})}</p>
                                                            <p>lng: {artItem.longitude.toLocaleString('nl-NL', {maximumFractionDigits: 6})}</p>
                                                        </div>
                                                        <div className="art">

                                                                <button onClick={() => openSelectArt(i)}>Selecteer kunst</button>

                                                            {artItem.art !== null && typeof loadedArt[artItem.art] !== "undefined" && <>
                                                                <img src={loadedArt[artItem.art].artworkUrl} alt={loadedArt[artItem.art].title}/>
                                                            </>}
                                                        </div>
                                                        <div className="inputs">
                                                            <div>
                                                                <p>hoogte</p>
                                                                <input type="number" name="altitude" value={artItem.altitude} onChange={e => updateArt(i, e.target.name, e.target.value)}/>
                                                            </div>
                                                            <div>
                                                                <p>kijk afstand</p>
                                                                <input type="number" name="viewingDistance" value={artItem.viewingDistance} onChange={e => updateArt(i, e.target.name, e.target.value)}/>
                                                            </div>
                                                            <div>
                                                                <p>kunst breedte</p>
                                                                <input type="number" name="artworkWidth" value={artItem.artworkWidth} onChange={e => updateArt(i, e.target.name, e.target.value)}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        )}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    }
                </> : <>
                    {tourInputData.art.length === 0 ? <p>Deze tour bevat nog geen kunstwerken</p> :
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {tourInputData.art.map((artItem, i) => (
                                            <Draggable key={artItem.id} draggableId={generateDraggableId(artItem, i)} index={i}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        className="position-item"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        onMouseEnter={(e) => itemHover(e, i)}
                                                    >
                                                                 <div key={i} className="position-item" onMouseEnter={e => itemHover(e, i)}>
                                                                     <div className="number">
                                                                         {i + 1}
                                                                     </div>
                                                                     <div className="pos">
                                                                         <p>lat: {artItem.latitude.toLocaleString('nl-NL', {maximumFractionDigits: 6})}</p>
                                                                         <p>lng: {artItem.longitude.toLocaleString('nl-NL', {maximumFractionDigits: 6})}</p>
                                                                     </div>
                                                                     <div className="art">

                                                                             <button onClick={() => openSelectArt(i)}>Selecteer kunst</button>

                                                                         {artItem.art !== null && typeof loadedArt[artItem.art] !== "undefined" && <>
                                                                             <img src={loadedArt[artItem.art].artworkUrl} alt={loadedArt[artItem.art].title}/>
                                                                         </>}
                                                                     </div>
                                                                     <div className="inputs">
                                                                         <div>
                                                                             <p>hoogte</p>
                                                                             <input type="number" name="altitude" value={artItem.altitude} onChange={e => updateArt(i, e.target.name, e.target.value)}/>
                                                                         </div>
                                                                         <div>
                                                                             <p>kijk afstand</p>
                                                                             <input type="number" name="viewingDistance" value={artItem.viewingDistance} onChange={e => updateArt(i, e.target.name, e.target.value)}/>
                                                                         </div>
                                                                         <div>
                                                                             <p>kunst breedte</p>
                                                                             <input type="number" name="artworkWidth" value={artItem.artworkWidth} onChange={e => updateArt(i, e.target.name, e.target.value)}/>
                                                                         </div>
                                                                     </div>
                                                                 </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>

                    }
                </>}
      </div>

      <div className="add-arttour--column">
        <textarea
          className="inputfield inputfield--description"
          type="text"
          name="description"
          placeholder="Omschrijving"
          value={tourInputData.description}
          onChange={handleChange}
        />
                <button
          className="addartwork-button"
          onClick={() => setLeafletMapOpen(true)}
        >
          Kunstwerk toevoegen +
                </button>

        <LeafletMap
          trigger={leafletMapOpen}
          setTrigger={setLeafletMapOpen}
          positionClicked={positionClicked}
          selectedPositions={selectedPositions}
          changeLocation={changeLocation}
          setChangeLocation={setChangeLocation}
          locationToChange={tourInputData.art[hovered]}
        />
      </div>

      <div className="add-arttour--column add-arttour--column-last">
        <LeafletMapEdit art={tourInputData.art} loadedArt={loadedArt}/>

        <div className="buttons-error-container">
            <p className="error-message">&#8203;{errorMessage}</p>
            {
                <div id="add-arttour--buttons">
                    <button onClick={leavePagePopup}>Annuleren</button>
                    <button onClick={postTour}>Tour opslaan</button>
                </div>
            }
        </div>
      </div>
            <SelectArt artUpdating={artUpdating} setArtUpdating={setArtUpdating} setSelectedArt={setSelectedArt}/>
            <Buttons {...buttonsState} deleteArt={deleteSelectedArt} viewArt={viewSelectedArt} changeLocation={handleChangeLocation} edit={edit} />
        </div>
  );
}

export default TourInputContainer;
