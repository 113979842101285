import { NavLink } from "react-router-dom";
import getInitials from "../../utils/getInitials";
import setCookie from "../../utils/setCookie";

function HamburgerNavbar(props) {
    const { userIsAmin } = props;

    return (
        <div id="hamburgernavbar" className={`${props.hamburgerMenuOpen ? "hamburgernavbar--show" : "hamburgernavbar--hide"}`}>
            <span>
                <div className="hamburgernavbar--user-icon">
                    {props.user.imageUrl ? <img src={props.user.imageUrl} alt="profielfoto" /> : <p>{getInitials(props.user.name)}</p>}
                </div>
                <p className="hamburgernavbar--user-name">{props.user.name}</p>
            </span>
            {
                props.user.name !== "Niet ingelogd" ?
                    <ul>
                        <NavLink
                            to="/kunstwerken"
                            className={({ isActive }) =>
                                (isActive ? "hamburgernav--selected " : "hamburgerlink ") + "hamburgernavlink"
                            }
                            onClick={props.updateHamburgerMenu}
                        >
                            <li>
                                Kunstwerken
                            </li>
                        </NavLink>
                        <NavLink
                            to="/kunsttours"
                            className={({ isActive }) =>
                                (isActive ? "hamburgernav--selected " : "hamburgerlink ") + "hamburgernavlink"
                            }
                            onClick={props.updateHamburgerMenu}
                        >
                            <li>
                                Kunsttours
                            </li>
                        </NavLink>
                        {
                            userIsAmin &&
                            <NavLink
                                to="/gebruikers"
                                className={({ isActive }) =>
                                    (isActive ? "hamburgernav--selected " : "hamburgerlink ") + "hamburgernavlink"
                                }
                                onClick={props.updateHamburgerMenu}
                            >
                                <li>
                                    Gebruikers
                                </li>
                            </NavLink>
                        }
                    </ul> : <span></span>
            }

            <span>
                <NavLink
                    to="/"
                    onClick={(() => {
                        setCookie("token", "", -1);
                        props.updateUser("Niet ingelogd");
                        props.updateHamburgerMenu();

                    })}
                >
                    {props.user.name === "Niet ingelogd" ? "Inloggen" : "Uitloggen"}
                </NavLink>
            </span>
        </div>
    )
}



export default HamburgerNavbar;