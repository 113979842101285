import { useState } from 'react';
import { useNavigate, NavLink } from "react-router-dom";
import api from '../../utils/apiCall';
import checkAdminAccess from '../../utils/checkAdminAccess';
import decodeJwt from '../../utils/decodeJwt';
import setCookie from '../../utils/setCookie';

function Login(props){
    const { updateUserIsAdmin } = props;
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const [loginData, setLoginData] = useState({
        email: "",
        password: ""
    });

    function handleChange(evt) {
        const value = evt.target.value;
        setLoginData((prev) => {return {
            ...prev,
            [evt.target.name]: value
        }});
    }

    function handleLoginClick() {
        let body = {
            ...loginData,
            email: loginData.email.toLowerCase()
        }

        api("/login", "POST", body).then((result) => {
                if (result.success === false){
                    setErrorMessage(result.userMessage);
                } else {
                    setCookie("token", result.token, 365);
                    let username = decodeJwt(result.token).username;
                    props.updateUser(username);

                    setErrorMessage("");
                    setLoginData({
                        email: "",
                        password: ""
                    });
                    updateUserIsAdmin(() => {return (checkAdminAccess(updateUserIsAdmin))});
                    navigate("/kunstwerken");
                }
            }
        );
    }

    return (
        <div id="login">
            <div id="login--container">
                <h1>Inloggen</h1>
                
                <label className='input'>
                    <p>Email</p>
                    <input
                        type="text"
                        name="email"
                        value={loginData.email}
                        onChange={handleChange}
                        onKeyDown={(e) => e.key === 'Enter' && handleLoginClick()}
                    />
                </label>
                <label className='input'>
                    <p>Wachtwoord</p>
                    <input
                        type="password"
                        name="password"
                        value={loginData.password}
                        onChange={handleChange}
                        onKeyDown={(e) => e.key === 'Enter' && handleLoginClick()}
                    />
                </label>

                <p className='error-message'>&#8203;{errorMessage}</p>

                <button id="loginButton" onClick={handleLoginClick}>
                    Login 
                </button>

                <NavLink to="/wachtwoordherstellen" id={"forgotemail"}>
                    Wachtwoord vergeten?
                </NavLink>
            </div>
        </div>
    )
}

export default Login;