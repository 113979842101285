import { MapContainer } from "react-leaflet/MapContainer";
import { TileLayer } from "react-leaflet/TileLayer";
import { useMap, useMapEvents } from "react-leaflet/hooks";
import { Marker, Popup } from "react-leaflet";
import "leaflet-routing-machine";

function LeafletMapEdit(props) {
    const { art, loadedArt } = props;
    var center = [52.3722582, 5.2176582]

    if (art.length > 0) {
        const lats = art.map(a => a.latitude);
        const lngs = art.map(a => a.longitude);
        center = [
            (Math.min(...lats) + Math.max(...lats)) / 2,
            (Math.min(...lngs) + Math.max(...lngs)) / 2
        ];
    }

    return (
        <div className="map-edit-container">
            <MapContainer
                className="map-edit-content"
                center={center}
                zoom={16}
                maxZoom={19}
            >
                <TileLayer
                    maxZoom={20}
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                {art.map((art, i) => <Marker key={i} position={[art.latitude, art.longitude]}>
                    {art.art !== null && typeof loadedArt[art.art] !== "undefined" && <Popup>
                        <img className="markerImage" src={loadedArt[art.art].artworkUrl} alt={loadedArt[art.art].title}/>
                        <h2>{loadedArt[art.art].title}</h2>
                    </Popup>}
                </Marker>)}
            </MapContainer>
        </div>
    );
}
export default LeafletMapEdit;
