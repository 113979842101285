import serverLocation from "./serverLocation";
import getCookie from "./getCookie";
import popup from "./popup";

console.log("hier begint de call");
var aanroep = (serverLocation + "/v1");
console.log(aanroep);

//Make api call with given parameters
function api(endpoint, method = "GET", reqData = {}) {
    return fetch(serverLocation + "/v1" + endpoint, {
        method: method,
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization:  getCookie("token"),
        },
        body: method === "GET" ? null : JSON.stringify(reqData),
    }).then((res) => res.json()).then(async (data) => {
        const res = await popup(data);
        if (res === true) {
            return await api(endpoint, method, reqData);
        }
        return res;
    });
}

export default api;
