import { useState } from "react";
import PageTitle from "../../../components/PageTitle/PageTitle";
import ArtInputContainer from "../components/ArtInputContainer/ArtInputContainer";
import api from "../../../utils/apiCall";
import uploadFile from "../../../utils/uploadFile";
import serverLocation from "../../../utils/serverLocation";
import PopupMessage from "../../../components/PopupMessage/PopupMessage";

function AddArt() {
    const [popupState, setPopupState] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [artworkInputData, setArtworkInputData] = useState({
        title: "",
        artist: "",
        medium: "",
        isForSale: false,
        yearOfCreation: "",
        websiteUrl: "",
        formatHeight: "",
        formatWidth: "",
        priceCurrency: "",
        price: "",
        description: "",
        otherInformation: "",
        artworkUrl: ""
    });
    const [selectedFile, setSelectedFile] = useState(undefined);

    function updateSelectedFile(file) {
        setSelectedFile(file);
    }

    function updateArtworkInputData(evt, value) {
        setArtworkInputData((prev) => {
            return {
                ...prev,
                [evt.target.name]: value
            }
        });
    }

    function leavePagePopup() {
        setPopupState({
            popupActive: true,
            buttonNavigates: true,
            popupNavigation: "/kunstwerken/",
            popupType: "confirm",
            popupMessage: "Weet u zeker dat u deze pagina wilt verlaten? Niet opgeslagen informatie zal verloren gaan."
        });
    }


    function updatePopupState() {
        setPopupState((prev) => {
            return {
                ...prev,
                popupActive: !prev.popupActive
            }
        })
    }

    function postArtwork() {
        const requiredFields = ["title", "artist", "yearOfCreation", "description"];
        for (let i = 0; i < requiredFields.length; i++) {
            if (artworkInputData[requiredFields[i]] === ""
                || artworkInputData[requiredFields[i]] === " "
                || artworkInputData[requiredFields[i]] === undefined
            ) {
                setErrorMessage("Niet alle benodigde velden zijn ingevuld");
                return;
            }
        }

        if (selectedFile === undefined) {
            setErrorMessage("Er is geen bestand geselecteerd");
            return;
        }

        let supportedFileTypes = ["image/png", "image/jpeg"]
        if (!supportedFileTypes.includes(selectedFile.type)) {
            setErrorMessage("Bestandstype wordt niet ondersteund");
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        uploadFile("/upload", formData).then((result) => {
            if (result.success === false){
                setErrorMessage(result.userMessage);
            } else {
                setErrorMessage("");
                postArtworkData(result.fileName);               
            }
        });
    }

    function postArtworkData(fileName) {
        let body = {
            artworkUrl: serverLocation + fileName
        };

        for (let key in artworkInputData) {
            if (artworkInputData[key] !== "" && artworkInputData[key] !== " " && artworkInputData[key] !== undefined) {
                body[key] = artworkInputData[key];
            }
        }

        if (artworkInputData.formatHeight && artworkInputData.formatWidth) {
            body.artworkSize = artworkInputData.formatHeight + " x " + artworkInputData.formatWidth;
        } else if (artworkInputData.formatHeight || artworkInputData.formatWidth) {
            body.artworkSize = artworkInputData.formatHeight ? artworkInputData.formatHeight + "%$H" : artworkInputData.formatWidth + "%$W";
        }

        delete body["formatHeight"];
        delete body["formatWidth"];


        api("/art", "POST", body).then((result) => {
            if (result.success === false) {
                setErrorMessage(result.userMessage);
            } else {
                setErrorMessage("");
                setPopupState({
                    popupActive: true,
                    buttonNavigates: true,
                    popupNavigation: "/kunstwerken/",
                    popupType: "accept",
                    popupMessage: "Kunstwerk toevoegen is gelukt. U wordt nu teruggestuurd naar het overzicht."
                });
            }
        });
    }

    return (
        <div id="add-art">
            <div id="add-art--header">
                <p className="back-link" onClick={leavePagePopup}>
                    {"< Terug"}
                </p>
                <PageTitle title="Kunstwerk toevoegen" />
            </div>

            <div id="add-art--body">
                <h4>Velden met een * zijn verplicht</h4>

                <ArtInputContainer
                    isAddingArt={true}
                    inputsDisabled={false}
                    artworkInputData={artworkInputData}
                    updateArtworkInputData={updateArtworkInputData}
                    updateSelectedFile={updateSelectedFile}
                    selectedFile={selectedFile}
                    postArtwork={postArtwork}
                    errorMessage={errorMessage}
                    leavePagePopup={leavePagePopup}
                    isEditingArt={true}
                />

                <PopupMessage
                    popupState={popupState}
                    updatePopupState={updatePopupState}
                />
            </div>
        </div>
    )
}

export default AddArt;