import { useNavigate } from "react-router-dom";

const TourItem = (props) => {
    const navigate = useNavigate();
    const { tour, updateSelectedTourId, deleteTourPopup } = props;

    const goToTour = () => {
        updateSelectedTourId(tour._id);
        navigate("/kunsttours/kunsttour-wijzigen");
    }

    const trimmedDescription = tour.description.substr(0, 200);
    var shorterDescription = trimmedDescription;

    if (trimmedDescription.length < tour.description.length)
        shorterDescription = trimmedDescription.substr(0, Math.min(trimmedDescription.length, trimmedDescription.lastIndexOf(" ")));

    return <>
        <td>{tour.title}</td>
        <td>{tour.theme}</td>
        <td>{tour.totalDistance.toLocaleString()} Km</td>
        <td>{tour.type}</td>
        <td>
            {shorterDescription}
            {shorterDescription.length < tour.description.length && "..."}
        </td>
        <td>{tour.art.length}</td>
        <td>
            <div className="buttonGroup">
                <button onClick={goToTour}>Wijzig</button>
                <button onClick={() => deleteTourPopup(tour._id)}>Verwijderen</button>
            </div>
        </td>
    </>
}

export default TourItem;