import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import PopupMessage from "../../../components/PopupMessage/PopupMessage";
import api from "../../../utils/apiCall";
import ErrorMessage from "./ErrorMessage/ErrorMessage";
import Pagination from "../../../components/Pagination/Pagination";
import TourItem from "./TourItem/TourItem";

function ArttourOverview(props) {
    const navigate = useNavigate();
    const [tours, setTours] = useState([]);
    const [error, setError] = useState(null);
    const [popupState, setPopupState] = useState({});
    const [tourId, setTourId] = useState(null);
    const [totalTours, setTotalTours] = useState(0);
    const [page, setPage] = useState(1);

    useEffect(() => {
        getArtTours();
    }, [])

    useEffect(() => {
        getArtTours(page);
    }, [page]);

    const getArtTours = async (page = 1) => {
        const data = await api(`/tours/${page}`);
        if (!data.success) return setError(data.userMessage);

        setTotalTours(data.count);
        setTours(data.tours);
    }

    const updatePopupState = () => {
        setPopupState((prev) => { 
            return{
                ...prev,
                popupActive: !prev.popupActive
            }
        });
    }

    const deleteTourPopup = (_tourId) => {
        setTourId(_tourId);
        setPopupState({
            popupActive: true,
            buttonNavigates: false,
            popupType: "delete",
            popupMessage: "Wilt u echt deze tour verwijderen?"
        });
    }

    const deleteTour = async () => {
        if (tourId === null) return;

        updatePopupState();
        const data = await api(`/tour/${tourId}`, "DELETE");
        if (!data.success) return setError(data.userMessage);

        setTours(
            tours.filter(tour => tour._id !== tourId)
        );
        setTourId(null);
        setPopupState({});
    }

    const goToPage = (_page) => setPage(_page);

    return(
        <div id="arttour-overview">
            <div id="arttour-overview--header">
                <PageTitle title="Kunsttours" />
            </div>
            <div id="arttour-overview--buttons">
                    <button className="artwork-overview--add-button" onClick={() => {navigate("/kunsttours/kunsttour-toevoegen")}}>
                        Tour toevoegen +
                    </button>
            </div>

            {error === null && <>
                <table id="arttour-overview--table">
                    <thead>
                        <tr>
                            <th>Titel</th>
                            <th>Thema</th>
                            <th>Afstand</th>
                            <th>Type</th>
                            <th>Beschrijving</th>
                            <th>Hoeveel kunst</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tours.map(tour => <tr key={tour._id}>
                            <TourItem tour={tour} updateSelectedTourId={props.updateSelectedTourId} deleteTourPopup={deleteTourPopup}/>
                        </tr>)}
                    </tbody>
                </table>

                <Pagination currentPage={page} total={totalTours} setPage={goToPage}/>
            </>}

            {error !== null && <ErrorMessage message={error}/>}

            <PopupMessage 
                popupState={popupState}
                updatePopupState={updatePopupState}
                functionToRun={deleteTour}
            />

        </div>
    )
}

export default ArttourOverview;