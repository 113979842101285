import { useEffect } from "react";
import { useState } from "react";
import apiCall from "../../../../utils/apiCall";

const SelectArt = ({ artUpdating, setArtUpdating, setSelectedArt, selectedArtworks }) => {
    const [search, setSearch] = useState("");
    const [art, setArt] = useState([]);
    const [page, setPage] = useState(1);
    const [mode, setMode] = useState("static");
    
    const onClickEvent = e => {
        if (e.target.className === "select-art--bg") {
            setArtUpdating(-1);
        }
    }

    const getArt = async () => {
        const data = await apiCall(`/art/page/${page}`);
        if (data.success) {
            return setArt(data.art);
        }
        
        setArtUpdating(-1);
    }

    const searchArt = async () => {
        const data = await apiCall(`/search/art?q=${search}`);
        if (data.success) {
            return setArt(data.art);
        }
        
        setArtUpdating(-1);
    }

    const handleSelectArt = (artId) => {
        setSelectedArt(artId);
        setArtUpdating(-1);
    };

    const prevPage = () => (page - 1 > 0) && setPage(page - 1);
    const nextPage = () => (art.length === 30) && setPage(page + 1);

    const selectArt = artId => {
        setSelectedArt(artId);
    }

    useEffect(() => {
        if (search === "") {
            if (mode === "search") {
                setMode("static");
                setPage(1);
            }

            getArt();
            return;
        }
        
        if (mode === "static") {
            setMode("search");
            setPage(1);
        }
        searchArt();
    }, [search, page]);

    if (artUpdating === -1) return;

    return <>
        <div className="select-art--bg" onClick={onClickEvent}>
            <div className="select-art--popup">
                <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Zoek"/>
                
                <div className="select-art--popup-art">
                    {art.map((_art) => (
                        <div key={_art._id} onClick={() => selectArt(_art._id)}>
                            <img src={_art.artworkUrl} alt={_art.title} />
                            <div>{_art.title}</div>
                        </div>
                    ))}
                </div>

                <div className="select-art--popup-art-pagination">
                    <button onClick={prevPage}>{"<"}</button>
                    <button onClick={nextPage}>{">"}</button>
                </div>
            </div>
        </div>
    </>;
}

export default SelectArt;