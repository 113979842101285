import { useNavigate } from "react-router-dom";
import DragDropFile from '../DragDropFile/DragDropFile';

function ArtInputContainer(props) {
    const navigate = useNavigate();
    const { artworkInputData, updateArtworkInputData, updateSelectedFile, selectedFile, postArtwork, errorMessage, leavePagePopup, isAddingArt, isEditingArt, deleteArtPopup, enableEditMode, disableEditMode, updateArtwork, artworkTours, updateSelectedTourId } = props;

    function handleChange(evt) {
        let value = artworkInputData[evt.target.name];
        let input = evt.target.value;

        if (evt.target.name === "yearOfCreation"){
            const rgx = /^[0-9]{0,4}$/;
            if (rgx.test(input)){
                value = input;
            }
        } else if (evt.target.name === "price"){
            const rgx = /^[0-9]{1,}\.?[0-9]{0,}$/;
            input = input.replace(",", ".");
            if (rgx.test(input) || input === ""){
                value = input;
            }
        } else if (evt.target.type === "checkbox") {
            value =  evt.target.checked;
        } else {
            value = input;
        }
        updateArtworkInputData(evt, value);
    }

    return (
        <div id="art-input-container">
            <div className="add-art--column">
                <input
                    className="inputfield"
                    type="text"
                    name="title"
                    placeholder='Titel*'
                    value={artworkInputData.title}
                    onChange={handleChange}
                    disabled={!isEditingArt}
                />
                <input
                    className="inputfield"
                    type="text"
                    name="artist"
                    placeholder='Artiest*'
                    value={artworkInputData.artist}
                    onChange={handleChange}
                    disabled={!isEditingArt}
                />
                <input
                    className="inputfield"
                    type="text"
                    name="medium"
                    placeholder='Medium'
                    value={artworkInputData.medium}
                    onChange={handleChange}
                    disabled={!isEditingArt}
                />
                <div className="inputfield checkbox">
                    <input
                        type="checkbox"
                        name="isForSale"
                        checked={artworkInputData.isForSale}
                        onChange={handleChange}
                        disabled={!isEditingArt}
                    />
                    <span className="greyed-text">Te koop</span>
                </div>
                <textarea
                    className="inputfield inputfield--description"
                    type="text"
                    name="description"
                    placeholder='Omschrijving*'
                    value={artworkInputData.description}
                    onChange={handleChange}
                    disabled={!isEditingArt}
                />
            </div>

            <div className="add-art--column">
                <input
                    className="inputfield"
                    type="text"
                    name="yearOfCreation"
                    placeholder='Jaar van ontstaan*'
                    value={artworkInputData.yearOfCreation}
                    onChange={handleChange}
                    disabled={!isEditingArt}
                />
                <input
                    className="inputfield"
                    type="text"
                    name="websiteUrl"
                    placeholder='Link naar website'
                    value={artworkInputData.websiteUrl}
                    onChange={handleChange}
                    disabled={!isEditingArt}
                />
                <div className='format-container'>
                    <input
                        className="inputfield"
                        type="text"
                        name="formatHeight"
                        placeholder='Hoogte'
                        value={artworkInputData.formatHeight}
                        onChange={handleChange}
                        disabled={!isEditingArt}
                    />
                    <input
                        className="inputfield"
                        type="text"
                        name="formatWidth"
                        placeholder='Breedte'
                        value={artworkInputData.formatWidth}
                        onChange={handleChange}
                        disabled={!isEditingArt}
                    />
                </div>
                <input
                    className="inputfield"
                    type="text"
                    name="priceCurrency"
                    placeholder='Valuta'
                    value={artworkInputData.priceCurrency}
                    onChange={handleChange}
                    disabled={!isEditingArt}
                />
                <input
                    className="inputfield"
                    type="text"
                    name="price"
                    placeholder='Prijs'
                    value={artworkInputData.price}
                    onChange={handleChange}
                    disabled={!isEditingArt}
                />
                <textarea
                    className="inputfield inputfield--other-information"
                    type="text"
                    name="otherInformation"
                    placeholder='Overige informatie'
                    value={artworkInputData.otherInformation}
                    onChange={handleChange}
                    disabled={!isEditingArt}
                />
            </div>

            <div className="add-art--column add-art--column-last">
                {
                    isAddingArt ?
                        <div id="upload-file">
                            <DragDropFile updateSelectedFile={updateSelectedFile}/>
                            <h3>Geselecteerd:*</h3>
                            <p>{selectedFile ? selectedFile.name : "Geen bestand geselecteerd"}</p>
                        </div>
                    :   
                        <div id="display-artwork-info">
                            <div className='add-art--tourtable-container'>
                                <h3>Te vinden in deze tours:</h3>
                                {
                                    artworkTours === undefined ?
                                        <p>Laden...</p>
                                    : artworkTours.success === false ?
                                        <p className='error-message'>{artworkTours.userMessage}</p>
                                    : artworkTours.tours.length === 0 ?
                                        <p>Dit kuntwerk is aan geen enkele tour toegevoegd.</p>
                                    :
                                        <table>
                                            <tbody>
                                                { artworkTours.tours.map((tour) => { return (
                                                    <tr key={tour._id}>
                                                        <td>{tour.title}</td>
                                                        <td>
                                                            <button
                                                                onClick={() => {updateSelectedTourId(tour._id); navigate("/kunsttours/kunsttour-wijzigen")}}
                                                            >   
                                                                Wijzigen in tour
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )})}
                                            </tbody>
                                        </table>
                                }
                            </div>
                            <div className='display-artwork-info--image'>
                                <img src={artworkInputData.artworkUrl} alt="kunstwerk afbeelding"/>
                            </div>
                    </div>
                }
                
                <div className='buttons-error-container'>
                    <p className='error-message'>&#8203;{errorMessage}</p>
                    {
                        isAddingArt ? 
                            <div id="add-art--buttons">
                                <button onClick={leavePagePopup}>Annuleren</button>
                                <button onClick={postArtwork}>Kunstwerk toevoegen</button>
                            </div>
                        : isEditingArt ?
                            <div id="add-art--buttons">
                                <button onClick={disableEditMode}>Annuleren</button>
                                <button onClick={updateArtwork}>Wijzigingen opslaan</button>
                            </div>
                        :
                            <div id="add-art--buttons">
                                <button onClick={deleteArtPopup}>Verwijderen</button>
                                <button onClick={enableEditMode}>Wijzigen</button>
                            </div>
                    }   
                </div>
            </div>
        </div>
    )
}

export default ArtInputContainer;