import api from "./apiCall";
import decodeJwt from "./decodeJwt";
import getCookie from "./getCookie";

function checkAdminAccess(setAdmin) {
    const token = getCookie("token");
    if (token === "") {
        setAdmin(false);
        return;
    }

    const userData = decodeJwt(token);

    api("/roles/" + userData.roleId).then((result) => {
        if (result && result.success === true  && result.role.isAdmin === true) {
            setAdmin(true)
        }
        else {
            setAdmin(false)
        }
    })
}

export default checkAdminAccess;