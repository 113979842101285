import PageTitle from "../../../components/PageTitle/PageTitle";
import { useEffect, useState } from "react";
import api from "../../../utils/apiCall";
import PopupMessage from "../../../components/PopupMessage/PopupMessage";
import UserInputContainer from "../components/UserInputContainer/UserInputContainer";

function AddUser(props) {
    const [popupState, setPopupState] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [userInputData, setUserInputData] = useState({
        firstName: "",
        insertion: "",
        lastName: "",
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
        roleId: "",
    });
    const [userroles, setUserroles] = useState();

    useEffect(() => {
        getRoles();
    }, []);

    function getRoles() {
        api("/roles").then((result) => {
            if (result === undefined || result.success === false){
                setUserroles([])
            } else {
                setUserroles(result.roles);
                setUserInputData((prev) => { return {
                    ...prev,
                    roleId: result.roles[0]._id
                }});
            }
        });
    }

    function updateUserInputData(evt, value) {
        setUserInputData((prev) => {
            return {
                ...prev,
                [evt.target.name]: value
            }
        });
    }

    function leavePagePopup() {
        setPopupState({
            popupActive: true,
            buttonNavigates: true,
            popupNavigation: "/gebruikers/",
            popupType: "confirm",
            popupMessage: "Weet u zeker dat u deze pagina wilt verlaten? Niet opgeslagen informatie zal verloren gaan."
        });
    }


    function updatePopupState() {
        setPopupState((prev) => {
            return {
                ...prev,
                popupActive: !prev.popupActive
            }
        })
    }

    function postUser() {
        let body = {}

        let requiredFields = ["firstName", "lastName", "username", "email", "password", "confirmPassword"]
        for (let i = 0; i < requiredFields.length; i++){
            if (userInputData[requiredFields[i]].replaceAll(" ", "") === "" || userInputData[requiredFields[i]] === undefined) {
                setErrorMessage("Niet alle verplichte velden zijn ingevuld");
                return;
            }
        }
        
        if (userInputData.password !== userInputData.confirmPassword) {
            setErrorMessage("Wachtwoorden komen niet overeen");
            return;
        }

        let spaceRegex = /\s/;
        if (userInputData.password.match(spaceRegex)) {
            setErrorMessage("Een wachtwoord mag geen spaties bevatten"); 
            return;
        }

        let pwRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*.,"'/|()`~{}<>?;:=+-_])[a-zA-Z0-9!@#$%^&*.,"'/|()`~{}<>?;:=+-_]{8,}$/;
        if (!userInputData.password.match(pwRegex)) {
            setErrorMessage("Een wachtwoord moet bestaan uit ten minste 8 karakters, 1 nummer en 1 speciaal teken"); 
            return;
        }

        for (let key in userInputData) {
            if (key !== "confirmPassword" || userInputData[key] !== undefined || userInputData[key].replaceAll(" ", "") !== ""){
                body[key] = userInputData[key]
            }
        }

        api("/users", "POST", body).then((result) => {
            if (result.success === false) {
                setErrorMessage(result.userMessage);
            } else {
                setErrorMessage("");
                setPopupState({
                    popupActive: true,
                    buttonNavigates: true,
                    popupNavigation: "/gebruikers/",
                    popupType: "accept",
                    popupMessage: "Gebruiker toevoegen is gelukt. U wordt nu teruggestuurd naar het overzicht."
                });
            }
        });
    }

    return (
        <div id="add-user">
            <div id="add-user--header">
                <p className="back-link" onClick={leavePagePopup}>
                    {"< Terug"}
                </p>
                <PageTitle title="Gebruiker toevoegen" />
            </div>

            <div id="add-user--body">
                <h4>Velden met een * zijn verplicht</h4>

                <UserInputContainer
                    userInputData={userInputData}
                    updateUserInputData={updateUserInputData}
                    isAddingUser={true}
                    errorMessage={errorMessage}
                    leavePagePopup={leavePagePopup}
                    postUser={postUser}
                    userroles={userroles}
                />
            </div>

            <PopupMessage
                popupState={popupState}
                updatePopupState={updatePopupState}
            />
        </div>
    )
}

export default AddUser;