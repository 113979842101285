import { useLocation } from "react-router-dom";

const Wrapper = (props) => {
    const location = useLocation();
    if (location.pathname === "/popup") return <>{props.children}</>

    return <>
        <div id="wrapper">
            {props.children}
        </div>
    </>
}

export default Wrapper;