

const Buttons = props => {
    const {shown, x, y, deleteArt, viewArt, changeLocation, edit} = props;

    return <>
        <div className={"buttons-hover " + (shown && "show")} style={{top: y, left: x}}>
            <button onClick={changeLocation}>
                Map
            </button>
            {typeof edit !== "undefined" && (
                <button onClick={viewArt}>
                    View
                </button>
            )}


            <button onClick={deleteArt}>
                Delete
            </button>
        </div>
    </>
}

export default Buttons;